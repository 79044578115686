/* eslint-disable */
import React from 'react';
function Video(){
    return(
        <div className="top-100">
            <div className="row justify-content-center" id="adr" >
                <div className="col-md-8 mt-4">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Apple Facetime
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse5" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/facetime.png" className="h-75 video-logo" alt="facetime"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='https://apps.apple.com/ca/app/facetime/id1110145091' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.apple.com/guide/facetime/join-a-call-on-the-web-from-a-facetime-link-fctm2cd42547/mac' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://apps.apple.com/us/app/facetime/id1110145091'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.apple.com/en-us/HT212619' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Google Meet
                                    </h5>
                                </button>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/google-meet.png"  className="h-75 video-logo" alt="Google Meet"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='https://workspace.google.com/products/meet/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://meet.google.com' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://apps.google.com/meet/'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.google.com/a/users/answer/9282720?hl=en' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                       Microsoft Skype
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse2" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/skype.png" className="h-75 video-logo" alt="Skype"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='http://www.skype.com' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.skype.com/en/free-conference-call/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.skype.com/en/get-skype/'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.skype.com/en/skype/all/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Microsoft Teams
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse3" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'> 
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/ms-team.png" className="h-75 video-logo" alt="Ms Team"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='http://www.microsoft.com/teams' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.microsoft.com/en-us/microsoft-teams/join-a-meeting' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.microsoft.com/en-us/microsoft-teams/download-app'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.microsoft.com/en-us/teams' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        WhatsApp by Meta
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse6" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/whatsapp.png" className="h-75 video-logo" alt="whatsapp"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='http://www.whatsapp.com' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://web.whatsapp.com/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.whatsapp.com/download'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://faq.whatsapp.com/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Zoom
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse4" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                                            <img src="/assets/images/zoom-logo.jpg" className="h-75 video-logo" alt="zoom"/>
                                        </div>
                                        <div className='col-md-6'>
                                            <p className='text-center'>
                                                <a href='https://www.zoom.us' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                    Information
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.zoom.us/join' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Join/Start
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://www.zoom.us/download'  target="_blank" rel="noreferrer"className='btn btn-dark mr-1 col-md-8  video-button'>
                                                    Downloads
                                                </a>
                                            </p>
                                            <p className='text-center'>
                                                <a href='https://support.zoom.us/hc/en-us' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8  video-button'>
                                                    Help
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Video;