/* eslint-disable */
import React, {useState, useEffect} from 'react';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return; // Return undefined to indicate no cleanup function
    }
  
    function handleResize() {
      setWindowSize(getSize());
    }
  
    window.addEventListener('resize', handleResize);
  
    // Return the cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}



