/* eslint-disable */
import React, { useState } from "react";
import PropTypes from 'prop-types';
import CalcButton from './calcButton';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ButtonsContainer = (props) => {
  const { click, keyDown, total } = props;
  const [copied, setCopied] = useState(false);
  return (
    <div className="calc__btns-container" onClick={click} onKeyDown={keyDown} aria-hidden="true">
      <CalcButton name="clear" value="Clear" colored/>
      <CopyToClipboard text={total} onCopy={() => setCopied(true)}>
            <button className="btn btn--colored">Copy</button>
      </CopyToClipboard>
      <CalcButton name="percentage" value="%" colored/>
      <CalcButton name="division" value="÷" colored />

      <CalcButton name="number" value="7" />
      <CalcButton name="number" value="8" />
      <CalcButton name="number" value="9" />
      <CalcButton name="mult" value="x" colored />

      <CalcButton name="number" value="4" />
      <CalcButton name="number" value="5" />
      <CalcButton name="number" value="6" />
      <CalcButton name="substr" value="-" colored />

      <CalcButton name="number" value="1" />
      <CalcButton name="number" value="2" />
      <CalcButton name="number" value="3" />
      <CalcButton name="sum" value="+" colored />

      <CalcButton name="number" value="0" span={2} />
      <CalcButton name="floating" value="." />
      <CalcButton name="result" value="=" equal />
    </div>
  );
};

ButtonsContainer.propTypes = {
  click: PropTypes.func,
  keyDown: PropTypes.func,
  total: PropTypes.any.isRequired,
};

ButtonsContainer.defaultProps = {
  click: () => -1,
  keyDown: () => -1,
};

export { ButtonsContainer as default };
