/* eslint-disable */
import * as React from 'react';
import { Provider, Flex } from '@fluentui/react-northstar';
import { useState, useEffect } from 'react';
import { useTeams } from 'msteams-react-base-component';
import { app } from '@microsoft/teams-js';
import Main from './components/Main';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css/animate.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/style.css';


/**
 * Implementation of the Toolkit.law App content page
 */
export const ToolkitLawAppTab = () => {

    const [{ inTeams, theme, context }] = useTeams();
    const [entityId, setEntityId] = useState<string | undefined>();

    useEffect(() => {
        if (inTeams === true) {
            app.notifySuccess();
        } else {
            setEntityId('Not in Microsoft Teams');
        }
    }, [inTeams]);

    useEffect(() => {
        if (context) {
            setEntityId(context.page.id);
        }
    }, [context]);

    /**
     * The render() method to create the UI of the tab
     */
    return (
        <Provider>
            <Router>
                <div className="PopupApp">
                    <Main/>
                </div>
            </Router>
        </Provider>
    );
};
