/* eslint-disable */
import * as React from "react";
import Header from './includes/Header';
import Footer from './includes/Footer';
import Content from './Content';

function Main()
{

    return (
        <div className="app">
            <div className='container-fluid p-0'>
                <Header/>
                <Content/>
                <Footer/>
            </div>
        </div>
    );
}

export default Main;