/* eslint-disable */
import React, { useState, useRef } from 'react';
import FileSaver from 'file-saver';

import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint, faClipboard } from '@fortawesome/free-solid-svg-icons';

import { copyImageToClipboard } from 'copy-image-clipboard'

import './QrCode.css';

function QrCode(){

    const componentVCard = useRef(null);
    const componentUrl = useRef(null);
    const messageToUser = useRef(null);
    
    //> Use State
    const [qrCode,setQrCode] = useState("...")

    //> Url
    const [nameOfWebsite,setNameofWebsite] = useState("")
    const [addressOfWebsite,setAddressOfWebsite] = useState("")
    
    //> VCard
    const [fname,setFName]              = useState("")
    const [lname,setLName]              = useState("")
    const [mobilePhno,setMobilePhoneNo] = useState("")
    const [email,setEmail]              = useState("")

    const [addressLineOne,setAddressLineOne] = useState("")
    const [addressLineTwo,setAddressLineTwo] = useState("")
    const [city, setCity] = useState("")
    const [stateProvince, setStateProvince] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [country,setCountry] = useState("")
    const [website,setWebsite] = useState("")

    const [title,setTitle] = useState("")
    const [organization,setOrganization] = useState("")
    
    //> Text Message
    const [message,setMessage] = useState("Message To User")
    
    //> Functions
    const handleUrlForm = (e) => {
        e.preventDefault()
        setQrCode(`Name: ${nameOfWebsite} \nAddress: ${addressOfWebsite}`);
    }

    const handleVCardForm = (e) =>{
        e.preventDefault()
        setQrCode(`First Name: ${fname} 
            \nLast Name: ${lname} 
            \nMobile Ph no: ${mobilePhno} 
            \nEmail : ${email} 
            \nAddress Line One: ${addressLineOne}
            \nAddress Line Two: ${addressLineTwo}
            \nCity: ${city}
            \nState / Province: ${stateProvince}
            \nPostal Code: ${postalCode}
            \nCountry: ${country}
            \nTitle: ${title}
            \nOrganization: ${organization}
        `)
    }

    const handlePlainTextForm = (e) =>{
        e.preventDefault()
        setQrCode(`Message : ${message}`);
    }

    const downloadPNG=() => {
        FileSaver.saveAs(`https://api.qrserver.com/v1/create-qr-code/?data=${qrCode}&size=${200}x${200}`
            , `QRC-Editor-File-Name.png`);     
    }


    const resetQrCode = () => {
        setQrCode("")
        setFName("");
        setLName("");
        setMobilePhoneNo("");
        setEmail("")
        setNameofWebsite("");
        setAddressOfWebsite("");
        setAddressLineOne("");
        setAddressLineTwo("");
        setCity("");
        setStateProvince("");
        setPostalCode("");
        setCountry("");
        setMessage("")
    }


    //-> Copy Image to Clipboard
    const copytoClipboardVCard = async blob => {
        const img_src = document.getElementById("vCardImg") as HTMLImageElement;
        copyImageToClipboard(img_src.src,)
        .then(() => {
            console.log('Image Copied')
        })
        .catch((e) => {
            console.log('Error: ', e.message)
        })
       
    }

    const copytoClipboardVWebAddress = async blob => {
        const img_src = document.getElementById("WebAddressImg") as HTMLImageElement;
        copyImageToClipboard(img_src.src,)
        .then(() => {
            console.log('Image Copied')
        })
        .catch((e) => {
            console.log('Error: ', e.message)
        })
    }
    
    const copytoClipboardTextMessage = async blob => {
        const img_src = document.getElementById("TextMsgImg") as HTMLImageElement;
        copyImageToClipboard(img_src.src,)
        .then(() => {
            console.log('Image Copied')
        })
        .catch((e) => {
            console.log('Error: ', e.message)
        })
    }
     
    return(
        <div className='top-100' id="qrcode">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div id="accordionQRCode">
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#info" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Instructions
                                    </h5>
                                </button>
                            </div>
                            <div id="info" className="collapse" aria-labelledby="headingOne" data-parent="#accordionQRCode">
                                <div className="card-body">
                                    <p className="mb-0">
                                        "QRC" stands for "Quick Reference Code". Use this utility to generate a QRC that 
                                        when scanned can provide the user your contact information in the vcard format
                                         to import directly into your phone's contacts, a special message, or a website
                                          address.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Contact Information 
                                    </h5>
                                </button>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionQRCode">
                                <div className="card-body">
                                    <div className="row justify-content-center" >
                                    {/* QR Info */}
                                        <form className="col-12" onSubmit={handleVCardForm}>
                                            <div className='row'>
                                                <div className="col-md-6" >
                                                    <label>First Name <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setFName(e.target.value)} 
                                                            placeholder='ABC' 
                                                            required
                                                            value={fname}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Last Name <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setLName(e.target.value)} 
                                                            placeholder='ABC' 
                                                            required
                                                            value={lname}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Mobile Phone <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setMobilePhoneNo(e.target.value)} 
                                                            placeholder='+01010101010101' 
                                                            required
                                                            value={mobilePhno}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label> Email <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control col-md-12"
                                                            onChange={(e) => setEmail(e.target.value)} 
                                                            placeholder='abc@example.com' 
                                                            required
                                                            value={email}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Title</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setTitle(e.target.value)} 
                                                            placeholder='ABC XYZ' 
                                                            value={title}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Organization</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setOrganization(e.target.value)} 
                                                            placeholder='ABC XYZ' 
                                                            value={organization}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-6" >
                                                    <label>Address Line One</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setAddressLineOne(e.target.value)} 
                                                            placeholder='ABC XYZ' 
                                                            value={addressLineOne}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Address Line Two </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setAddressLineTwo(e.target.value)} 
                                                            placeholder='ABC XYZ' 
                                                            value={addressLineTwo}
                                                        />
                                                    </div>
                                                </div>

                                                

                                                <div className="col-md-6" >
                                                    <label>City</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setCity(e.target.value)} 
                                                            placeholder='ABC' 
                                                            value={city}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="col-md-6" >
                                                    <label>State / Province </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setStateProvince(e.target.value)} 
                                                            placeholder='XYZ'
                                                            value={stateProvince}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Postal Code </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setPostalCode(e.target.value)} 
                                                            placeholder='1234' 
                                                            value={postalCode}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Country </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setCountry(e.target.value)} 
                                                            placeholder='ABC'                                                
                                                            value={country}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6" >
                                                    <label>Website </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setWebsite(e.target.value)} 
                                                            placeholder='www.abc.com'                                                
                                                            value={website}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div className='d-flex justify-content-end mt-2'>
                                                        <button type='submit' className='mr-2 btn btn-warning'>Generate</button>
                                                        <button className='mr-2 btn btn-dark'
                                                                onClick={()=>{ 
                                                                    setFName("");
                                                                    setLName("");
                                                                    setMobilePhoneNo("");
                                                                    setEmail("");
                                                                    setNameofWebsite("");
                                                                    setAddressOfWebsite("");
                                                                    setAddressLineOne("");
                                                                    setAddressLineTwo("");
                                                                    setCity("");
                                                                    setStateProvince("");
                                                                    setPostalCode("");
                                                                    setCountry("");
                                                                }}>
                                                                    Clear
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='card' style={{ marginTop:"10px"}}>
                                                <div ref={componentVCard} >
                                                    <div className='d-flex flex-column justify-content-center' >
                                                        <h3 style={{ margin:"auto",marginTop:"12px" }}><b>Scan QR Code</b></h3> 
                                                        <img
                                                            id = "vCardImg"
                                                            style={{ margin:"auto",marginTop:"12px" }}
                                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCode}&size=${200}x${200}`}
                                                            alt=""
                                                            height={150}
                                                            width={150}
                                                        />   
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-center qrbtns' style={{marginTop:'5px'}}>
                                                    <button className='mr-2 btn btn-success' onClick={downloadPNG} > 
                                                        <FontAwesomeIcon icon={faDownload} />  Download
                                                    </button>
                                                    <ReactToPrint
                                                        trigger={() => <button className='mr-2 btn btn-success'> <FontAwesomeIcon icon={faPrint} /> Print</button>}
                                                        content={() => componentVCard.current}
                                                    />
                                                    <button className='mr-2 btn btn-success' onClick={copytoClipboardVCard}  > 
                                                        <FontAwesomeIcon icon={faClipboard} /> Copy
                                                    </button>     
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Text Message 
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse2" className="collapse " aria-labelledby="headingOne" data-parent="#accordionQRCode">
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <form className="col-md-12" onSubmit={handlePlainTextForm}>
                                            <div className='row'>
                                                <div className="col-md-6" >
                                                    <label>Message to User<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setMessage(e.target.value)} 
                                                            value={message}
                                                            name="" id=""
                                                            placeholder='E.g ABCDEF'                                                     
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-md-12 my-2 d-flex justify-content-end'>
                                                    <button type='submit' className='mr-2 btn btn-warning'>Generate</button>
                                                    <button className='mr-2 btn btn-dark' 
                                                        onClick={()=>{ 
                                                            setMessage("");
                                                        }}>
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div ref={messageToUser} >        
                                                    <div className='d-flex flex-column justify-content-center' id="qrcode" >
                                                        <h3 style={{ margin:"auto",marginTop:"12px" }}><b>{message}</b></h3> 
                                                        <img 
                                                            id="TextMsgImg"
                                                            style={{ margin:"auto",marginTop:"12px" }}
                                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCode}&size=${200}x${200}`}
                                                            alt=""
                                                            height={150}
                                                            width={150}
                                                        />   
                                                    </div>
                                                </div>   
                                                    
                                                <div className='d-flex justify-content-center qrbtns'>
                                                    <button className='mr-2 btn btn-success' onClick={downloadPNG}  > 
                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                    </button>
                                                    
                                                    <ReactToPrint
                                                        trigger={() => <button className='mr-2 btn btn-success'><FontAwesomeIcon icon={faPrint} /> Print</button>}
                                                        content={() =>  messageToUser.current}
                                                    />
                                                    <button className='mr-2 btn btn-success' onClick={copytoClipboardTextMessage}  > 
                                                        <FontAwesomeIcon icon={faClipboard} /> Copy
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Web Address
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse3" className="collapse " aria-labelledby="headingOne" data-parent="#accordionQRCode">
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        {/* QR Info */}
                                        <form className="col-md-12" onSubmit={handleUrlForm}>
                                            <div className='row'>
                                                <div className="col-md-6" >
                                                    <label>Text Above QR Code Image <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setNameofWebsite(e.target.value)} 
                                                            value={nameOfWebsite}
                                                            name="" id=""
                                                            placeholder='E.g. My URL' 
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6" >
                                                    <label>Website Address <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control col-md-12"
                                                            onChange={(e) => setAddressOfWebsite(e.target.value)}
                                                            pattern="^(http:\/\/www\.).*" 
                                                            value={addressOfWebsite} 
                                                            name="" id=""
                                                            placeholder='http://www.example.com'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12 my-2 d-flex justify-content-end'>
                                                    <button type='submit' className='mr-2 btn btn-warning'>Generate</button>
                                                    <button className='mr-2 btn btn-dark' 
                                                        onClick={()=>{ 
                                                            setNameofWebsite("");
                                                            setAddressOfWebsite("");
                                                        }}>
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='card'>
                                                <div ref={componentUrl} >        
                                                    <div className='d-flex flex-column justify-content-center' >
                                                        <h3 style={{ margin:"auto",marginTop:"12px" }}><b>{nameOfWebsite}</b></h3>
                                                        <img 
                                                            id="WebAddressImg"
                                                            style={{ margin:"auto",marginTop:"12px" }}
                                                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCode}&size=${200}x${200}`}
                                                            alt=""
                                                            height={150}
                                                            width={150}
                                                        />   
                                                    </div>
                                                </div>   
                                                    
                                                <div className='d-flex justify-content-center qrbtns'>
                                                    <button className='mr-2 btn btn-success'  onClick={downloadPNG}  > 
                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                    </button>
                                                    <ReactToPrint
                                                        trigger={() => <button className='mr-2 btn btn-success'><FontAwesomeIcon icon={faPrint} /> Print</button>}
                                                        content={() =>  componentUrl.current}
                                                    />
                                                    <button className='mr-2 btn btn-success' onClick={copytoClipboardVWebAddress}  > 
                                                        <FontAwesomeIcon icon={faClipboard} /> Copy
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QrCode;