import React from "react";
import PropTypes from 'prop-types';

function UploadButton(props) {
    const { title, onChange, accept, multiple } = props;

    return (
        <div className="form-group">
            <label className="btn btn-dark col-md-12 d-flex justify-content-center">
                <input type="file" multiple={multiple} className='d-none' accept={accept} onChange={onChange}/>
                <span>{title}</span>
            </label>
        </div>
    );
}

UploadButton.propTypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    accept: PropTypes.string,
    multiple: PropTypes.bool
};

export default UploadButton;
