/* eslint-disable */
import React, { useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import { PDFDocument } from 'pdf-lib';
import { pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faHand } from '@fortawesome/free-solid-svg-icons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DraggableSignature(props) {
    const {
        initialTop,
        initialLeft,
        pdfBytes,
        setModifiedPdf,
        handleUpdateFile,
        pageNumber,
        legalName,
        ipAddress,
        signatureURL,
        signatureTime,
        signatureDate,
        setSignatureDragableVisiable,
        setPdfBytes,
        setLegalName,
        setSignatureURL
    } = props;

    const [top, setTop] = useState(initialTop);
    const [left, setLeft] = useState(initialLeft);
    const ref = useRef<HTMLDivElement>(null);

    const bind = useDrag(({ down, movement: [mx, my] }) => {
        if (down && mx && my) {

            if (ref.current) {
                ref.current.style.cursor = 'grabbing';
            }
            
            // Calculate the boundaries of the PDF file
            const pdfWidth = 600; // Width of a letter-sized PDF in points
            const pdfHeight = 800; // Height of a letter-sized PDF in points
            const textFieldWidth = 200; // Width of the text field in pixels
            const textFieldHeight = 50; // Height of the text field in pixels
            const maxWidth = pdfWidth - textFieldWidth;
            const maxHeight = pdfHeight - textFieldHeight;

            // Calculate the new position of the text field
            const newTop = Math.max(0, Math.min(maxHeight, initialTop + my));
            const newLeft = Math.max(0, Math.min(maxWidth, initialLeft + mx));

            // Update the state only if the new position is within the boundaries
            setTop(newTop);
            setLeft(newLeft);
        } else {
            if (ref.current) {
                ref.current.style.cursor = 'grab';
            }
        }
    });

    const handleSaveClick = async () => {
        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Loop through all the text fields and add them to the first page
        const pages = pdfDoc.getPages();
        const textFont = await pdfDoc.embedFont('Helvetica');

        const page = pages[pageNumber];

        // Add a new text field to the page
        const textFieldContent = {
            size: 12,
            font: textFont
        };

        const response = await fetch(signatureURL);
        const signatureArrayBuffer = await response.arrayBuffer();
        const signatureUint8Array = new Uint8Array(signatureArrayBuffer);
        const signatureImage = await pdfDoc.embedPng(signatureUint8Array);
        const scaleFactor = 40 / signatureImage.height; //
        const y = page.getHeight() - top - textFieldContent.size;
        page.drawImage(signatureImage, {
            x: left + textFieldContent.size,
            y: y - 100,
            ...textFieldContent,
            width: signatureImage.width * scaleFactor,
            height: signatureImage.height * scaleFactor
        });

        const legalNameText = `${legalName}`;
        page.drawText(legalNameText, {
            x: left + textFieldContent.size,
            y: y - 115,
            ...textFieldContent
        });

        const dateTimeText = `${signatureDate} ${signatureTime} / ${ipAddress}`;
        page.drawText(dateTimeText, {
            x: left + textFieldContent.size,
            y: y - 130,
            ...textFieldContent
        });

        // Update the modified PDF document and set the state
        const modifiedPdfBytes = await pdfDoc.save();
        setModifiedPdf(new Uint8Array(modifiedPdfBytes));
        setPdfBytes(new Uint8Array(modifiedPdfBytes));
        handleUpdateFile(new Uint8Array(modifiedPdfBytes));
    };

    const handleClickClear = () => {
        setLegalName('');
        setSignatureURL(null);
        setSignatureDragableVisiable(false);
    };

    return (
        <div
            className="draggable-text-field d-flex justify-content-center"
            ref={ref}
            {...bind()}
            style={{ position: 'absolute', top: `${top}px`, left: `${left}px`, cursor: 'grab' }}
        >
            <div className='w-auto'>
                <div className="border p-2 mx-auto">
                    <div className="d-flex justify-content-center">
                        <button className='btn btn-warning'>
                        <FontAwesomeIcon icon={faHand}/>
                        </button>
                    </div>
                    <img src={signatureURL} alt="signature" className='signature-img'/>
                    <p className="mb-1 font-12 text-center">{legalName}</p>
                    <p className='font-9 mb-1 text-center'>{signatureDate}   {signatureTime} </p>
                    <p className='font-9 mb-1 text-center'> {ipAddress}</p>
                </div>
                <div className='btn-group w-100'>
                    <button className='btn btn-success w-50 ' onClick={handleSaveClick}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </button>
                    <button className='btn btn-danger w-50' onClick={handleClickClear}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DraggableSignature;
