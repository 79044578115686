/* eslint-disable */
import React from "react";
import { BigButton } from "./BigButton";
import { primary45 } from "../../../../../helpers/colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export function PagingControl({ totalPages, pageNum, setPageNum }) {
    const styles = {
        container: {
            marginTop: 8,
            marginBottom: 8
        },
        inlineFlex: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        pageInfo: {
            padding: 8,
            color: "#000000",
            fontSize: 14
        }
    };
    return (
        <div style={styles.container}>
            <div style={styles.inlineFlex}>
                <BigButton
                    title={<FontAwesomeIcon icon={faArrowLeft} />}
                    onClick={() => setPageNum(pageNum - 1)}
                    disabled={pageNum - 1 === -1}
                    customfillcolor = "#f7971c"
                    inverted={false}
                    fullWidth={false}
                    customWhiteColor="#ffffff"
                    style={{}}
                    noHover={false}
                    id=""
                    small={false}
                    marginRight={0}
                />
                <div style={styles.pageInfo}>
          Page: {pageNum + 1}/{totalPages}
                </div>
                <BigButton
                    title={<FontAwesomeIcon icon={faArrowRight} />}
                    onClick={() => setPageNum(pageNum + 1)}
                    disabled={pageNum + 1 > totalPages - 1}
                    customfillcolor = "#f7971c"
                    inverted={false}
                    fullWidth={false}
                    customWhiteColor="#ffffff"
                    style={{}}
                    noHover={false}
                    id=""
                    small={false}
                    marginRight={0}
                />
            </div>
        </div>
    );
}
