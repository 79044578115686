/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';
import UploadButton from '../../../../common/UploadButton';
import { downloadURI } from '../../../../../helpers/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes,
    faArrowCircleDown,
    faArrowCircleUp
  } from '@fortawesome/free-solid-svg-icons';

function Merge(){

    const [pdfFiles, setPdfFiles] = useState([]); //multiple pdf files
    const iconFontSize = "medium";
    const handleFileChange = async (event) => {
        const files = event.target.files;
        const newPdfs = [];

        if(pdfFiles.length != 0)
        {
            setPdfFiles([...pdfFiles, ...files]);
        }
        else{
            for (const file of files) {
                if (file.type === 'application/pdf') {
                    newPdfs.push(file);
                } 
                else {
                    console.error('Invalid file type:', file.type);
                }
            }
        
            setPdfFiles(newPdfs);
        }

        // console.log(newPdfs)
    };
    
    const handleMergeClick = async () => {
        const mergedPdf = await mergePDFs(pdfFiles);
        const mergedPdfUrl = URL.createObjectURL(new Blob([await mergedPdf.save()], { type: 'application/pdf' }));
        downloadURI(mergedPdfUrl, "merge-pdfs");
    };

    async function mergePDFs(pdfFiles) {
        const mergedPdf = await PDFDocument.create();
        
        for (const pdf of pdfFiles) {
            const pdfBytes = await pdf.arrayBuffer();
            const pdfDoc = await PDFDocument.load(pdfBytes);
            
            const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            pages.forEach((page) => mergedPdf.addPage(page));
        }
        
        return mergedPdf;
    }    
    
    const handleClickClear = () => {
        setPdfFiles([]);
    }

    const handleClickRemoveFile = (index) => {
        console.log(index);
        pdfFiles.splice(index, 1); 
    }

    const moveUp = (index) => {
        if (index > 0) {
            const newFiles = [...pdfFiles];
            const temp = newFiles[index];
            newFiles[index] = newFiles[index - 1];
            newFiles[index - 1] = temp;
            setPdfFiles(newFiles);
        }
    };
    
    const moveDown = (index) => {
        if (index < pdfFiles.length - 1) {
            const newFiles = [...pdfFiles];
            const temp = newFiles[index];
            newFiles[index] = newFiles[index + 1];
            newFiles[index + 1] = temp;
            setPdfFiles(newFiles);
        }
    };

    useEffect(() => {
       //do something
    }, [pdfFiles]);
    
    return(
        <>
            <div className='row justify-content-center'>
                <div className='col-md-3'>
                    <UploadButton 
                        title="Select PDF Files" 
                        multiple={true} 
                        accept="application/pdf" 
                        onChange={(e) => handleFileChange(e)} />
                </div>
            </div>
            
            {pdfFiles.length > 0 ? ( 
                <>
                    <div className='row'>                        
                        {
                            pdfFiles.map((pdf, index) => (
                                <div className='col-md-12 d-flex justify-content-center align-items-start'>
                                    <div className='col-md-4 d-flex justify-content-center '>
                                        <span>{pdf.name}</span>
                                    </div>
                                    <div className='col-md-3 mergebtn'>
                                        <button className='btn p-0 ml-4 rounded'  onClick={() => moveUp(index)} disabled={index === 0}>
                                            <FontAwesomeIcon icon={faArrowCircleUp}/>
                                        </button>
                                        <button className='btn p-0 rounded' onClick={() => moveDown(index)} disabled={index === pdfFiles.length - 1}>
                                            <FontAwesomeIcon icon={faArrowCircleDown}/>
                                        </button>
                                        <button className='btn p-0 rounded' onClick={() => handleClickRemoveFile(index)}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </button>
                                    </div>                                    
                                </div>    
                            )) 
                        }
                        <div className='col-md-12 mt-3 d-flex justify-content-center'>
                            <button className='btn btn-warning mr-4' onClick={handleMergeClick}>Merge PDFs</button>
                            <button className='btn btn-dark' onClick={handleClickClear}>Clear</button>
                        </div>
                    </div>
                </>
            ): null }         
        </>
    );
}
export default Merge;