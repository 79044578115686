/* eslint-disable */
import React from 'react';
import Converter from './converter/Converter';
import Rotate from './rotate/Rotate';
// import View from './view/View';
import Merge from './Merge/Merge';
import Annotate from './annotate/Annotate';
import Signature from './signature/Signature';

function PdfPage() {

    return (
        <div className='top-100' id="pdfpage">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div id="accordionPdfPage">
                        <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#Annotate" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    Annotate
                                    </h5>
                                </button>
                            </div>
                            <div id="Annotate" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <Annotate/>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#Convert" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    Convert
                                    </h5>
                                </button>
                            </div>
                            <div id="Convert" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <Converter/>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#Merge" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    Merge
                                    </h5>
                                </button>
                            </div>
                            <div id="Merge" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <Merge/>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#Rotate" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    Rotate
                                    </h5>
                                </button>
                            </div>
                            <div id="Rotate" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <Rotate/>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#Sign" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    Sign
                                    </h5>
                                </button>
                            </div>
                            <div id="Sign" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <Signature/>
                                </div>
                            </div>
                        </div>
                        {/* <div className='card'>
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#View" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">
                                    View
                                    </h5>
                                </button>
                            </div>
                            <div id="View" className="collapse" aria-labelledby="headingOne" data-parent="#accordionPdfPage">
                                <div className="card-body">
                                    <View/>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PdfPage;
