/* eslint-disable */
import React from "react";
import { primary45 } from "../../../../../helpers/colors";
import useHover from "../../../../../hooks/useHover";

export function BigButton({
    title,
    onClick,
    inverted,
    fullWidth,
    customfillcolor,
    customWhiteColor,
    style,
    noHover,
    id,
    small,
    disabled,
    marginRight
}) {
    const [hoverRef, isHovered] = useHover();

    let fillColor = customfillcolor || primary45;
    const whiteColor = customWhiteColor || "#FFF";

    let initialBg = fillColor;
    let hoverBg = fillColor;

    let initialColor = whiteColor;
    let hoverColor = whiteColor;

    if (inverted) {
        initialBg = fillColor;
        hoverBg = null;
        initialColor = whiteColor;
        hoverColor = fillColor;
    }

    if (disabled) {
        initialBg = "#ddd";
        hoverBg = "#ddd";
        fillColor = "#ddd";
    }

    const styles = {
        container: {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: fullWidth ? "100%" : null,
            backgroundColor: isHovered && !noHover ? hoverBg : initialBg,
            color:
        isHovered && !noHover && !disabled
            ? hoverColor
            : disabled
                ? "#999"
                : initialColor,
            borderRadius: 4,
            padding: small ? "2px 4px" : "6px 8px",
            fontSize: small ? 14 : null,
            border: `1px solid ${fillColor}`,
            cursor: !disabled ? "pointer" : null,
            userSelect: "none",
            boxSizing: "border-box",
            marginRight
        }
    };

    return (
        <div
            id={id}
            style={{ ...styles.container, ...style }}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        >
            {title}
        </div>
    );
}
