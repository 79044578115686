/* eslint-disable */
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import DraggableTextField from '../pdfComponents/DraggableTextField';
import UploadButton from '../../../../common/UploadButton';
import { PagingControl } from '../pdfComponents/PagingControl';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faTimes,
    faTextWidth,
    faSearchPlus,
    faSearchMinus,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import '../pdfEditor.css';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Anotate() {
    const [pdfBytes, setPdfBytes] = useState(null);
    const [originalPdfBytes, setOriginalPdfBytes] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pageNum, setPageNum] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [modifiedPdf, setModifiedPdf] = useState(null);
    const [dragableFieldVisible, setDragableFieldVisible] = useState(false);
    const [scale, setScale] = useState(1); // zoom in/out
    const [zoom, setZoom] = useState('');

    const onFileChange = async (event) => {
        const file = event.target.files[0];
        const data = await file.arrayBuffer({ maxSize: 50 * 1024 * 1024 }); // Increase the max buffer size to 50 MB
        const pdfBytes = new Uint8Array(data);
        setPdfBytes(pdfBytes);
        setPdfUrl(URL.createObjectURL(file));
        setOriginalPdfBytes(pdfBytes);
    };

    const handleDocumentClick = () => {
        if (!dragableFieldVisible) {
            setDragableFieldVisible(true);
        }
    };

    const handleClickClear = () => {
        setPdfBytes(null);
        setPdfUrl('');
        setDragableFieldVisible(false);
        setOriginalPdfBytes(null);
    };

    const handleUpdateFile = (modifiedPdf) => {
        const blob = new Blob([modifiedPdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
    };

    const handleDownloadClick = async () => {
        if (modifiedPdf) {
            const blob = new Blob([modifiedPdf], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `annotation.pdf`;
            link.click();
        }
    };

    const handleZoomInClick = () => {
        setScale(scale + 0.2);
        setZoom('zoomed-in');
    };

    const handleZoomOutClick = () => {
        setScale(scale - 0.2);
        setZoom('');
    };

    const windowSize = useWindowSize();

    const handleDeleteAnnotatedText = async () => {
        if (modifiedPdf) {
            setModifiedPdf(originalPdfBytes);
            setPdfBytes(originalPdfBytes);
            handleUpdateFile(originalPdfBytes);
        }
    };

    return (
        <>
            <div className="row justify-content-center" id="pdfeditor">
                {!pdfBytes
                    ? (
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="row justify-content-center">
                                <UploadButton
                                    title="Select PDF File"
                                    accept="application/pdf"
                                    onChange={(e) => onFileChange(e)}
                                />
                            </div>
                        </div>
                    )
                    : null}
                {pdfBytes && (
                    <>
                        <div className='row'>
                            <div className={windowSize.width < 768 ? 'col-md-1 mt-3 d-flex justify-content-center' : 'col-md-1 mt-3'}>
                                <div className= {windowSize.width < 768 ? 'btn-group' : 'btn-group-vertical'}>

                                    <button className="btn btn-dark" title="Add Text Field" onClick={(e) => setDragableFieldVisible(true)}>
                                        <FontAwesomeIcon icon={faTextWidth} />
                                    </button>
                                    <button className="btn btn-dark" title="Zoom in" onClick={handleZoomInClick}>
                                        <FontAwesomeIcon icon={faSearchPlus} />
                                    </button>
                                    <button className="btn btn-dark" title="Zoom Out" onClick={handleZoomOutClick}>
                                        <FontAwesomeIcon icon={faSearchMinus} />
                                    </button>
                                    <button className="btn btn-dark" title='Download' onClick={handleDownloadClick}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                    <button className="btn btn-dark" title="Delete" onClick={handleDeleteAnnotatedText}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className="btn btn-dark" title='Close' onClick={handleClickClear}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-11'>
                                <div className='col-md-12 mt-3 d-flex justify-content-center'>
                                    <div onClick={handleDocumentClick} className={`pdf-wrapper border`} style={{ transform: `scale(${zoom})` }}>
                                        <Document
                                            file={pdfUrl}
                                            onLoadSuccess={(data) => {
                                                setTotalPages(data.numPages);
                                            }}
                                        >
                                            <Page
                                                key={`page_${pageNum}`}
                                                pageNumber={pageNum + 1}
                                                onRenderError={(error) => console.error(error)}
                                                width={600}
                                                height={800}
                                                renderTextLayer={false}
                                                scale={scale}
                                            >
                                                {dragableFieldVisible &&
                                                    <DraggableTextField
                                                        key={'dragable_text_field'}
                                                        top={100}
                                                        left={100}
                                                        pdfBytes={pdfBytes}
                                                        setModifiedPdf={setModifiedPdf}
                                                        handleUpdateFile={handleUpdateFile}
                                                        pageNumber={pageNum}
                                                        setPdfBytes={setPdfBytes}
                                                        setDragableFieldVisible={setDragableFieldVisible}
                                                    />
                                                }
                                            </Page>
                                        </Document>
                                    </div>
                                </div>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <PagingControl
                                        pageNum={pageNum}
                                        setPageNum={setPageNum}
                                        totalPages={totalPages}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default Anotate;
