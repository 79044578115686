/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate  } from 'react-router-dom';
import parse from 'html-react-parser';
import http from '../../services/httpService';
import config from "../../services/config.json";
import BasicCalculator from '../mathtab/BasicCalculator';
import Age from '../mathtab/AgeCalc';
import Salary from '../mathtab/SalaryCalculator';
import Video from '../tools/Video';
import Apps from '../tools/Apps';
import QrCode from '../tools/qrcode/QrCode';
// import NotepadPage from '../tools/notepad/NotepadPage';
import Conversion from '../mathtab/Conversion';
import Date from '../mathtab/DateCalculator';
import Clock from '../mathtab/Clock';
import Hours from '../mathtab/Hours';
import PdfPage from '../tools/pdfEditor/PdfPage';
import AutoLoanCalc from '../mathtab/AutoLoanCalc';

const MenuPageDetail = () => {
    const [menuPageDetails, setMenuPageDetails] = useState([]);
    const [menuPage, setMenuPage] = useState([]);
    let { menu, slug } = useParams();
    const navigate = useNavigate();

    const getMenuPageDetail = async () => {
        const { data } = await http.get(config.baseUrl + 'menu-page-detail/'+slug);
        const menuPageDetails = data.data.pageDetail;
        const menuPage = data.data.page;
        setMenuPage(menuPage);
        setMenuPageDetails(menuPageDetails);

    };

    const location = useLocation();
    useEffect(() => {
       getMenuPageDetail();
     }, [location]);
    
    return ( 
        <div className="page-content mt-0">
            <div className="sticky-top bg-white">
                <h2 className="title text-center">{ (menuPage as any).page_content_title }
                    <button onClick={() => navigate(-1)}  className="mt-1 back btn btn-warning btn-sm float-left">
                        <span className="material-icons" title="Back">reply</span>
                    </button>
                </h2>
            </div>
            
            { (menuPage as any).has_page_detail === 1 && (
                menuPageDetails.length > 0 && (
                    <div className='mt-4' key={(menuPageDetails[0] as any).id}>
                        { (menuPageDetails[0] as any).description ? parse((menuPageDetails[0] as any).description) : '' }
                    </div>
                )
            )}

            { (menuPage as any).id === 32 && (
                <BasicCalculator />
            )}

            { (menuPage as any).id === 33 && (
                <Salary />
            )}

            { (menuPage as any).id === 34 && (
                <Conversion />
            )}

            { (menuPage as any).id  === 36 && (
                <AutoLoanCalc />
            )}


            { (menuPage as any).id === 38 && (
                <Date />
            )}

            { (menuPage as any).id === 40 && (
                <Age />
            )}

            { (menuPage as any).id === 42 && (
                <Hours />
            )}

            {   //billable hours
                (menuPage as any).id === 43 && (
                <Clock />
            )}

            { (menuPage as any).id === 104 && (
                <Video />
            )}

          
            {(menuPage as any).id === 26 && (
                <PdfPage />
            )}

            { (menuPage as any).id === 25 && (
                <QrCode />
            )}

            { (menuPage as any).id === 23 && (
                <Apps />
            )}

        </div>
     );
}
 
export default MenuPageDetail;