/* eslint-disable */
import React, { useState, useEffect } from 'react';
import http from './services/httpService';
import config from "./services/config.json";
import MenuPageDetail from './content/routes/MenuPageDetail';
import { Routes, Route } from 'react-router-dom';
import MenuPageList from './content/routes/MenuPageList';
import {
    Link
} from "react-router-dom";

function Content() {

    const [extensionLogo, setExtensionLogo] = useState('');
    const [extensionPages, setExtensionPages] = useState([]); 

    const getExtensionSettings = async () => {
        const { data } = await http.get(config.baseUrl + 'get-team-extension-settings');
        setExtensionPages(data.data);
        setExtensionLogo(data.logo);
    };
    
    useEffect(() => {
        getExtensionSettings();
    }, []);

    return (
        <>
            <div className="row border bg-white fixed-top">
                <div className="col-md-12 d-flex justify-content-center">
                    <a href={config.publicSiteUrl}  rel="noreferrer"  target="_blank">
                        <img src={extensionLogo} alt="Logo" />
                    </a>
                </div>
            </div>
            <section className="content bottom-50 section"> 
                <Routes>
                    <Route path="/" element={<MenuPageList extensionPages={extensionPages}/>}   />
                    <Route path="/toolkitLawAppTab" element={<MenuPageList extensionPages={extensionPages}/>}   />
                    <Route path="/:menu/:slug" element={<MenuPageDetail />} />
                </Routes>
            </section>
        </> 
    );    
}

export default Content;