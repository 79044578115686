/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { PDFDocument, degrees } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import UploadButton from '../../../../common/UploadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

function Rotate() {
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [fileName, setFileName] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleClear = () => {
    setPdfDoc(null);
    setPdfFile(null);
    setImageUrl(null);
    setRotation(0);
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const loadedPdf = await PDFDocument.load(await file.arrayBuffer());
    setPdfDoc(loadedPdf);
    setPdfFile(file);
    setFileName(file.name.slice(0, file.name.lastIndexOf(".")));
  };

  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsDataURL(file);
        });
};

  const convertPdfToImage = async () => {
    if (!pdfFile) return; // Return if pdfFile is not set yet
    const data = await readFileData(pdfFile);
    const pdf = await pdfjsLib.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    let height = 0;
    let width = 0;
    
    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        height += viewport.height;
        width = Math.max(width, viewport.width);
    }
    
    canvas.width = width;
    canvas.height = height;
    let yOffset = 0;

    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        context.translate(0, yOffset);
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };
        await page.render(renderContext).promise;
        yOffset += viewport.height;
    }
    
    const dataUrl = canvas.toDataURL('image/png');
    setImageUrl(dataUrl);
    canvas.remove();
};


  const handleRotateClick = async () => {
    setIsLoading(true);
    const newRotation = rotation + 90;
    setRotation(newRotation);
    const pages = pdfDoc.getPages();
    pages.forEach((page) => {
      const currentRotation = page.getRotation().angle;
      page.setRotation(degrees(currentRotation + 90));
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
    convertPdfToImage();

  };

  const handleDownloadClick = async () => {
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}_rotated.pdf`;
    link.click();
  };

  useEffect(() => {
    if (pdfFile) {
      convertPdfToImage();
    }
  }, [imageUrl, pdfFile]);

  const windowSize = useWindowSize();
  return (
    <>
      <div className="row justify-content-center" id="pdfeditor">
        {!pdfDoc ? (
          <div className='col-md-12 d-flex justify-content-center'>
            <div className='row justify-content-center'>
              <UploadButton
                  title="Select PDF File" 
                  accept="application/pdf"
                  onChange={(e) => handleFileUpload(e)}
                  />
            </div>
          </div>
        ): null}
        
        <div className={windowSize.width < 768 ? 'col-md-1 mt-3 d-flex justify-content-center':'col-md-1 mt-3'}>
          {pdfDoc ? (
            <> 
              <div className= {windowSize.width < 768 ? 'btn-group':'btn-group-vertical'}>
                <button className='btn btn-dark' onClick={handleRotateClick} title="Rotate 90 degrees">
                  {isLoading ? 'Loading...' : <FontAwesomeIcon icon={faUndo}></FontAwesomeIcon>}
                </button>
                <button className='btn btn-dark' onClick={handleDownloadClick} title='Download'>
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </button>
                <button className='btn btn-dark' onClick={handleClear} title='Clear'>
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </button>
                
              </div>
            </>
          ) : null }
        </div>
        <div className='col-md-11 d-flex justify-content-center'>
          {imageUrl && pdfDoc ? (
              <>
                <img
                  src={imageUrl}
                  width={300}
                  height={300}
                  alt="PDF file"
                  style={{ transform: `rotate(${rotation}deg)` }}
                />
              </>
          ) : null }
        </div>        
      </div>
    </>
  );
}

export default Rotate;