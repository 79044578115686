/* eslint-disable */
import React, { useState, useEffect } from 'react';
import http from '../services/httpService';
import config from "../services/config.json";

function Footer(){

    const [menus, setMenus] = useState([]);
    const [adminUrl, setAdminUrl] = useState([]);
    const [publicUrl, setPublicUrl] = useState(config.publicSiteUrl);

    const getMenus = async () => {
        const { data } = await http.get(config.baseUrl + 'menus');
        const menus = data.data.menus;
        const adminUrl = data.data.url;
        setMenus(menus);
        setAdminUrl(adminUrl);
    };

    useEffect(() => {
       getMenus();
    }, []);

    return (
        <div className="row fixed-bottom bg-white">
            <div className="w-100 col-md-12 footer border-top px-4">
                {
                    menus.map((menu) => (
                        <a href={publicUrl  + (menu as any).name.toLowerCase()}  rel="noreferrer"  target="_blank" className="w-100 toolbar-menu">
                            <div className="w-100 text-center">
                                <img src={adminUrl + '/icons/' + (menu as any).icon} alt={(menu as any).name} />
                                <h4 className="text-center mb-0 pt-1 pl-2">{(menu as any).name}</h4>
                            </div>
                        </a>
                    ))
                }              
            </div>
        </div>
    );
}

export default Footer;