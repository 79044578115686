/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Age()
{
    
    let todayDate = moment().format('YYYY-MM-DD');
    const [noOfDays, setNoOfDays] = useState(0);
    const [noOfHours, setNoOfHours] = useState(0);
    const [noOfYears, setNoOfYears] = useState(0);
    const [noOfMonths, setNoOfMonths] = useState(0);
    const [noOfSeconds, setNoOfSeconds] = useState(0);
    const [noOfMinutes, setNoOfMinutes] = useState(0);
    const [noOfWeeks, setNoOfWeeks] = useState(0);
    const [dateOfBirth, setDateOfBirth] = useState(todayDate);
    const [ageAtTheDateOf, setAgeAtTheDateOf] = useState(todayDate);
    const [errorMessage, setErrorMessage] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [copied, setCopied] = useState(false);

    function handleChangeDateOfBirth(e){
        setDateOfBirth(e.target.value);
    }

    function handleChangeAgeAtTheDateOf(e){
        setAgeAtTheDateOf(e.target.value);
    }

    function handleClickCalculateAge()
    {
        let startDate = moment(dateOfBirth);
        let endDate = moment(ageAtTheDateOf);
        if (endDate >= startDate){
            setNoOfYears(endDate.diff(startDate, 'years'));
            setNoOfMonths(endDate.diff(startDate, 'months'));
            setNoOfWeeks(endDate.diff(startDate, 'weeks'));
            setNoOfDays(endDate.diff(startDate, 'days'));
            setNoOfHours(endDate.diff(startDate, 'hours'));
            setNoOfMinutes(endDate.diff(startDate, 'minutes'));
            setNoOfSeconds(endDate.diff(startDate, 'seconds'));
            setErrorMessage('');
            setShowResult(true);
        }else{
            setErrorMessage('Date of birth needs to be earlier than the age at date');
        }
        goToTop();

    }

    function handleClickClear(){
        setDateOfBirth(todayDate);
        setAgeAtTheDateOf(todayDate);
        setShowResult(false);
    }

    return(
        <div className='top-100'>
             <div id="age" className="w-100">
                <div className="container">                    
                    <div className='row justify-content-center'>
                        {errorMessage &&
                            <div className='col-md-8'>
                                <div className="errorMessage">
                                    <p>{errorMessage}</p>
                                </div>
                            </div>
                        }
                        { showResult && 
                            <div className='col-md-8'>
                                <div className='output pb-1'>
                                    <h3 className='bg-warning pl-3'>Result </h3>
                                    <div className=''>
                                        <p className='mb-1 pl-4'><strong>{noOfYears} years {noOfMonths} months {noOfDays} days</strong> </p>
                                        <div className='col-6'>
                                            <CopyToClipboard text={noOfYears + ' years ' +  noOfMonths + ' months '+  noOfDays +  ' days.'}
                                                    onCopy={() => setCopied(true)}>
                                                <button className="btn btn-dark btn-block">Copy</button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                                    
                            </div>
                        }
                        <div className="col-md-8 mt-2 pl-4"> 
                            <div className="form-group">
                                <label>Date of Birth</label>
                                <input type="date" value={dateOfBirth} onChange={handleChangeDateOfBirth}  className="form-control col-md-12" name="dob" id="dob"/>
                            </div>
                            <div className='form-group'>
                                <label>Age at the Date of </label>
                                <input type="date" value={ageAtTheDateOf} onChange={handleChangeAgeAtTheDateOf}  className='form-control col-md-12' name="new_date" id="new_date"/>
                            </div>
                            <div className='row mt-2 justify-content-center'>
                                <div className='col-6'>
                                    <button onClick={handleClickCalculateAge}  className="btn btn-warning btn-block">Calculate</button>
                                </div>
                                <div className='col-6'>
                                    <button onClick={handleClickClear} className='btn btn-dark btn-block'>Clear</button>
                                </div>
                            </div> 
                        </div>
                    </div>
                                           
                </div>
            </div>
        </div>
    );
}

export default Age;