// Default entry point for client scripts
// Automatically generated
// Please avoid from modifying to much...
import * as React from 'react';
import * as ReactDOM from 'react-dom';
export const render = (type: any, element: HTMLElement) => {
    ReactDOM.render(React.createElement(type, {}), element);
};
// Automatically added for the toolkitLawAppTab tab
export * from './toolkitLawAppTab/ToolkitLawAppTab';
export * from './toolkitLawAppTab/ToolkitLawAppTabConfig';
export * from './toolkitLawAppTab/ToolkitLawAppTabRemove';
