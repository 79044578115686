/* eslint-disable */
import React, { useState } from "react";
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';


function Conversion()
{
    const [showResult, setShowResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [copied, setCopied] = useState(false);

    function handleClickTabs(){
        setErrorMessage('');
        handleClickClearLength();
        handleClickClearVolume();
        handleClickClearArea();
        handleClickClearWeight();
        handleClearTemprature();
    }

    /**
     * Length Conversion
     */
    const [lengthFromValue, setLengthFromValue] = useState(0);
    const [lengthToValue, setLengthToValue] = useState(0);
    const [lengthFromUnit, setLengthFromUnit] = useState('meter');
    const [lengthToUnit, setLengthToUnit] = useState('kilometer');
    const [showLengthResult, setShowLengthResult] = useState(false);
    const [lengthArray, setLengthArray] = useState(getDefaultLength());

    function getDefaultLength(){
        const temps = [];
        temps['meter']= 0;
        temps['kilometer']= 0;
        temps['centimeter'] = 0;
        temps['millimeter']= 0;
        temps['micrometer']= 0;
        temps['nanometer'] = 0;
        temps['mile']= 0;
        temps['yerd']= 0;
        temps['foot'] = 0;
        temps['inch']= 0;
        temps['lightYear']= 0;
        return temps;
    }

    function handleChangeLengthFromValue(e){
        setLengthFromValue(e.target.value);
    }
   
    function handleChangeLengthFromUnit(e){
        setLengthFromUnit(e.target.value);
    }
   
    function handleChangeLengthToUnit(e){
        setLengthToUnit(e.target.value);
    }
   
    function handleClickCalculateLength(){
        if(lengthFromUnit === 'meter')
        {
            lengthArray['meter']= lengthFromValue;
            lengthArray['kilometer']= lengthFromValue / 1000.0;
            lengthArray['centimeter'] = lengthFromValue * 100;
            lengthArray['millimeter']= lengthFromValue * 1000;
            lengthArray['micrometer']= lengthFromValue * 1e+6;
            lengthArray['nanometer'] = lengthFromValue * 1e+9;
            lengthArray['mile']= lengthFromValue / 1609.0;
            lengthArray['yerd']= lengthFromValue * 1.094;
            lengthArray['foot'] = lengthFromValue * 3.281;
            lengthArray['inch']= lengthFromValue * 39.37;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 9.461e+15;
        }
        else if(lengthFromUnit === 'kilometer')
        {
            lengthArray['meter']= lengthFromValue * 1000;
            lengthArray['kilometer']= lengthFromValue;
            lengthArray['centimeter'] = lengthFromValue * 100000;
            lengthArray['millimeter']= lengthFromValue * 1e+6;
            lengthArray['micrometer']= lengthFromValue * 1e+9;
            lengthArray['nanometer'] = lengthFromValue * 1e+12;
            lengthArray['mile']= lengthFromValue / 1.609;
            lengthArray['yerd']= lengthFromValue * 1093.61;
            lengthArray['foot'] = lengthFromValue * 3281;
            lengthArray['inch']= lengthFromValue * 39370;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 9.461e+12;
        }
        else if(lengthFromUnit === 'centimeter')
        {
            lengthArray['meter']= lengthFromValue / 100.0;
            lengthArray['kilometer']= lengthFromValue / 100000.0;
            lengthArray['centimeter'] = lengthFromValue;
            lengthArray['millimeter']= lengthFromValue * 1e+6;
            lengthArray['micrometer']= lengthFromValue * 1e+9;
            lengthArray['nanometer'] = lengthFromValue * 1e+12;
            lengthArray['mile']= lengthFromValue / 1.609;
            lengthArray['yerd']= lengthFromValue * 1093.61;
            lengthArray['foot'] = lengthFromValue / 30.48;
            lengthArray['inch']= lengthFromValue / 2.54;
            
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 9.461e+17;
        }
        else if(lengthFromUnit === 'millimeter')
        {
            lengthArray['meter']= lengthFromValue / 1000.0;
            lengthArray['kilometer']= parseFloat(String(lengthFromValue)) / 1e+6;
            lengthArray['centimeter'] = lengthFromValue / 10.0;
            lengthArray['millimeter']= lengthFromValue;
            lengthArray['micrometer']= lengthFromValue * 1000;
            lengthArray['nanometer'] = lengthFromValue * 1e+6;
            lengthArray['mile']= parseFloat(String(lengthFromValue)) / 1.609e+6;
            lengthArray['yerd']= lengthFromValue / 914.4;
            lengthArray['foot'] = lengthFromValue / 304.8;
            lengthArray['inch']= lengthFromValue / 25.4;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) /  9.461e+18;
        }
        else if(lengthFromUnit === 'micrometer')
        {
            lengthArray['meter']= parseFloat(String(lengthFromValue)) / 1e+6;
            lengthArray['kilometer']= parseFloat(String(lengthFromValue)) / 1e+9;
            lengthArray['centimeter'] = lengthFromValue / 10000.0;
            lengthArray['millimeter']= lengthFromValue / 1000.0;
            lengthArray['micrometer']= lengthFromValue;
            lengthArray['nanometer'] = lengthFromValue * 1000;
            lengthArray['mile']= parseFloat(String(lengthFromValue)) / 1.609e+9;
            lengthArray['yerd']= lengthFromValue / 914400.0;
            lengthArray['foot'] = lengthFromValue / 304800.0;
            lengthArray['inch']= lengthFromValue / 25400.0;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 9.461e+21;
        }
        else if(lengthFromUnit === 'nanometer')
        {
            lengthArray['meter']= parseFloat(String(lengthFromValue)) / 1e+9;
            lengthArray['kilometer']= parseFloat(String(lengthFromValue)) / 1e+12;
            lengthArray['centimeter'] = parseFloat(String(lengthFromValue)) / 1e+7;
            lengthArray['millimeter']= parseFloat(String(lengthFromValue))/ 1e+6;
            lengthArray['micrometer']= lengthFromValue / 1000.0;
            lengthArray['nanometer'] = lengthFromValue;
            lengthArray['mile']= parseFloat(String(lengthFromValue)) / 1.609e+12;
            lengthArray['yerd']= parseFloat(String(lengthFromValue)) / 9.144e+8;
            lengthArray['foot'] = parseFloat(String(lengthFromValue)) / 3.048e+8;
            lengthArray['inch']= parseFloat(String(lengthFromValue)) / 2.54e+7;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 9.461e+24;
        }
        else if(lengthFromUnit === 'mile')
        {
            lengthArray['meter']= lengthFromValue * 1609;
            lengthArray['kilometer']= lengthFromValue * 1.609;
            lengthArray['centimeter'] = lengthFromValue * 160900;
            lengthArray['millimeter']= lengthFromValue * 1.609e+6;
            lengthArray['micrometer']= lengthFromValue * 1.609e+9;
            lengthArray['nanometer'] = lengthFromValue * 1.609e+12;
            lengthArray['mile']= lengthFromValue;
            lengthArray['yerd']= lengthFromValue * 1760;
            lengthArray['foot'] = lengthFromValue * 5280;
            lengthArray['inch']= lengthFromValue * 63360;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 5.879e+12;
        }
        else if(lengthFromUnit === 'yerd')
        {
            lengthArray['meter']= lengthFromValue / 1.094;
            lengthArray['kilometer']= lengthFromValue / 1094.0;
            lengthArray['centimeter'] = lengthFromValue * 91.44;
            lengthArray['millimeter']= lengthFromValue * 914.4;
            lengthArray['micrometer']= lengthFromValue * 914400;
            lengthArray['nanometer'] = lengthFromValue * 9.144e+8;
            lengthArray['mile']= lengthFromValue / 1760.0;
            lengthArray['yerd']= lengthFromValue;
            lengthArray['foot'] = lengthFromValue * 3;
            lengthArray['inch']= lengthFromValue * 36;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 1.035e+16;
        }
        else if(lengthFromUnit === 'foot')
        {
            lengthArray['meter']= lengthFromValue / 3.281;
            lengthArray['kilometer']= lengthFromValue / 3281.0;
            lengthArray['centimeter'] = lengthFromValue * 30.48;
            lengthArray['millimeter']= lengthFromValue * 304.8;
            lengthArray['micrometer']= lengthFromValue * 304800;
            lengthArray['nanometer'] = lengthFromValue * 3.048e+8;
            lengthArray['mile']= lengthFromValue / 5280.0;
            lengthArray['yerd']= lengthFromValue / 3.0;
            lengthArray['foot'] = lengthFromValue;
            lengthArray['inch']= lengthFromValue * 12;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 3.104e+16;
        }
        else if(lengthFromUnit === 'inch')
        {
            lengthArray['meter']= lengthFromValue / 39.37;
            lengthArray['kilometer']= lengthFromValue / 39370;
            lengthArray['centimeter'] = lengthFromValue * 2.54;
            lengthArray['millimeter']= lengthFromValue * 25.4;
            lengthArray['micrometer']= lengthFromValue * 25400;
            lengthArray['nanometer'] = parseFloat(String(lengthFromValue)) / 2.54e+7;
            lengthArray['mile']= lengthFromValue / 63360.0;
            lengthArray['yerd']= lengthFromValue / 36.0;
            lengthArray['foot'] = lengthFromValue / 12.0;
            lengthArray['inch']= lengthFromValue;
            lengthArray['lightYear']= parseFloat(String(lengthFromValue)) / 3.725e+17;
        }
        else if(lengthFromUnit === 'lightYear')
        {
            lengthArray['meter']= lengthFromValue * 9.461e+15;
            lengthArray['kilometer']= lengthFromValue * 9.461e+12;
            lengthArray['centimeter'] = lengthFromValue * 9.461e+17;
            lengthArray['millimeter']= lengthFromValue * 9.461e+18;
            lengthArray['micrometer']= lengthFromValue * 9.461e+21;
            lengthArray['nanometer'] = lengthFromValue * 9.461e+24;
            lengthArray['mile']= lengthFromValue * 5.879e+12;
            lengthArray['yerd']= lengthFromValue * 1.035e+16;
            lengthArray['foot'] = lengthFromValue * 3.104e+16;
            lengthArray['inch']= lengthFromValue * 3.725e+17;
            lengthArray['lightYear']= lengthFromValue;
        }
        setLengthToValue(lengthArray[lengthToUnit]);
        handleClearTemprature();
        handleClickClearArea();
        handleClickClearVolume();
        handleClickClearWeight();
        setShowResult(true);
        setShowLengthResult(true);
    }
   
    function handleClickClearLength(){
        setShowResult(false);
        setLengthToUnit('kilometer');
        setLengthFromUnit('meter');
        setShowLengthResult(false);
        setErrorMessage('');
        setLengthFromValue(0);
        setLengthToValue(0);
        setLengthArray(getDefaultLength());
    }

    /**
     * Temprature Conversion
     */

    const [tempFromValue, setTempFromValue] = useState(0);
    const [tempToValue, setTempToValue] = useState(0);
    const [tempFromUnit, setTempFromUnit] = useState('celcius');
    const [tempToUnit, setTempToUnit] = useState('celcius');
    const [ShowTempResult, setShowTempResult] = useState(false);
    const [tempArray, setTempArray] = useState(getDefaultTemp());


    function getDefaultTemp(){
        const temps = [];
        temps['celcius']= 0;
        temps['fahrenheit']= 0;
        temps['kelvin'] = 0;
        return temps;
    }

    function handleChangeTempFromValue(e) {
        setTempFromValue(e.target.value);
    }
    function handleChangeTempFromUnit(e) {
        setTempFromUnit(e.target.value);
    }
    function handleChangeTempToUnit(e) {
        setTempToUnit(e.target.value);
    }
    function handleClickCalculateTemprature ()
    {
        if(tempFromUnit === 'celcius')
        {
            tempArray['celcius'] = tempFromValue;
            tempArray['kelvin'] = (parseFloat(String(tempFromValue)) - 273.15).toFixed(2);
            tempArray['fahrenheit'] = (9/5 * tempFromValue) + 32;
        }
        else if(tempFromUnit === 'kelvin')
        {
            tempArray['celcius'] = parseFloat(String(tempFromValue)) + 273.15;
            tempArray['kelvin'] = tempFromValue;
            tempArray['fahrenheit'] = (9/5 * tempFromValue) - 459.67;
        }
        else if(tempFromUnit === 'fahrenheit')
        {
            tempArray['celcius'] = (5/9) * (tempFromValue - 32);
            tempArray['kelvin'] = (5/9) * (parseFloat(String(tempFromValue)) + 459.67);
            tempArray['fahrenheit'] = tempFromValue;
        }
        
        setTempToValue(tempArray[tempToUnit]);
        handleClickClearArea();
        handleClickClearVolume();
        handleClickClearWeight();
        handleClickClearLength();
        setShowResult(true);
        setShowTempResult(true);
    }

    function handleClearTemprature(){
        setShowResult(false);
        setErrorMessage('');
        setShowTempResult(false);
        setTempToUnit('celcius');
        setTempFromUnit('celcius');
        setTempFromValue(0);
        const defaultTemp = getDefaultTemp();
        setTempToValue(defaultTemp[tempFromUnit]);
    }

    /**
     * Area Conversion
     */

    const [areaFromValue, setAreaFromValue] = useState(0);
    const [areaToValue, setAreaToValue] = useState(0);
    const [areaFromUnit, setAreaFromUnit] = useState('square_meter');
    const [areaToUnit, setAreaToUnit] = useState('square_kilometer');
    const [showAreaResult, setShowAreaResult] = useState(false);
    const [AreaArray, setAreaArray] = useState(getDefaultArea());

    function getDefaultArea(){
        const temps = [];
        temps['square_meter']= 0;
        temps['square_kilometer']= 0;
        temps['square_centimeter'] = 0;
        temps['square_millimeter']= 0;
        temps['square_micrometer']= 0;
        temps['hectare'] = 0;
        temps['square_mile']= 0;
        temps['square_yard']= 0;
        temps['square_foot'] = 0;
        temps['square_inch']= 0;
        temps['square_acre']= 0;
        return temps;
    }

    function handleChangeAreaFromValue(e){
        setAreaFromValue(e.target.value);
    }

    function handleChangeAreaFromUnit(e){
        setAreaFromUnit(e.target.value);
    }

    function handleChangeAreaToUnit(e){
        setAreaToUnit(e.target.value);
    }

    function handleClickCalculateArea(){
        if(areaFromUnit === 'square_meter')
        {
            AreaArray['square_meter']= areaFromValue;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1e+6;
            AreaArray['square_centimeter'] = areaFromValue * 10000;
            AreaArray['square_millimeter']= areaFromValue * 1e+6;
            AreaArray['square_micrometer']= areaFromValue * 1e+12;
            AreaArray['hectare'] = areaFromValue / 10000.0;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 2.59e+6;
            AreaArray['square_yard']= areaFromValue * 1.196;
            AreaArray['square_foot'] = areaFromValue * 10.764;
            AreaArray['square_inch']= areaFromValue * 1550;
            AreaArray['square_acre']= areaFromValue / 4047.0;
        }
        else if(areaFromUnit === 'square_kilometer')
        {
            AreaArray['square_meter']= areaFromValue * 1e+6;
            AreaArray['square_kilometer']= areaFromValue;
            AreaArray['square_centimeter'] = areaFromValue * 10000;
            AreaArray['square_millimeter']= areaFromValue * 1e+12;
            AreaArray['square_micrometer']= areaFromValue * 1e+18;
            AreaArray['hectare'] = areaFromValue * 100;
            AreaArray['square_mile']= areaFromValue / 2.59;
            AreaArray['square_yard']= areaFromValue * 1.196e+6;
            AreaArray['square_foot'] = areaFromValue * 1.076e+7;
            AreaArray['square_inch']= areaFromValue * 1.55e+9;
            AreaArray['square_acre']= areaFromValue * 247.1;
        }
        else if(areaFromUnit === 'square_centimeter')
        {
            AreaArray['square_meter']= areaFromValue / 10000.0;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1e+10;
            AreaArray['square_centimeter'] = areaFromValue;
            AreaArray['square_millimeter']= areaFromValue * 100;
            AreaArray['square_micrometer']= parseFloat(String(areaFromValue)) * 1e+8;
            AreaArray['hectare'] = parseFloat(String(areaFromValue)) / 1e+8;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 2.59e+10;
            AreaArray['square_yard']= areaFromValue / 8361.0;
            AreaArray['square_foot'] = areaFromValue / 929;
            AreaArray['square_inch']= areaFromValue / 6.452;
            AreaArray['square_acre']= parseFloat(String(areaFromValue)) / 4.047e+7;
        }
        else if(areaFromUnit === 'square_millimeter')
        {
            AreaArray['square_meter']= parseFloat(String(areaFromValue)) / 1e+6;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1e+12;
            AreaArray['square_centimeter'] = areaFromValue / 100;
            AreaArray['square_millimeter']= areaFromValue;
            AreaArray['square_micrometer']= areaFromValue * 1e+6;
            AreaArray['hectare'] = parseFloat(String(areaFromValue)) / 1e+10;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 2.59e+12;
            AreaArray['square_yard']= areaFromValue / 836100; 
            AreaArray['square_foot'] = areaFromValue / 92900;
            AreaArray['square_inch']= areaFromValue / 645.2;
            AreaArray['square_acre']= parseFloat(String(areaFromValue)) / 4.047e+9;
        }
        else if(areaFromUnit === 'square_micrometer')
        {
            AreaArray['square_meter']= parseFloat(String(areaFromValue)) / 1e+12;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1e+18;
            AreaArray['square_centimeter'] = parseFloat(String(areaFromValue)) / 1e+8;
            AreaArray['square_millimeter']= parseFloat(String(areaFromValue)) / 1e+6;
            AreaArray['square_micrometer']= areaFromValue;
            AreaArray['hectare'] = parseFloat(String(areaFromValue)) / 1e+16;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 2.59e+18;
            AreaArray['square_yard']= parseFloat(String(areaFromValue)) / 8.361e+11;
            AreaArray['square_foot'] = parseFloat(String(areaFromValue)) / 9.29e+10;
            AreaArray['square_inch']= parseFloat(String(areaFromValue)) / 6.452e+8;
            AreaArray['square_acre']= parseFloat(String(areaFromValue)) / 4.047e+15;
        }
        else if(areaFromUnit === 'hectare')
        {
            AreaArray['square_meter']= areaFromValue * 10000;
            AreaArray['square_kilometer']= areaFromValue * 100;
            AreaArray['square_centimeter'] = areaFromValue * 1e+8;
            AreaArray['square_millimeter']= areaFromValue * 1e+10;
            AreaArray['square_micrometer']= areaFromValue * 1e+16;
            AreaArray['hectare'] = areaFromValue;
            AreaArray['square_mile']= areaFromValue / 259.0;
            AreaArray['square_yard']= areaFromValue / 11960.0;
            AreaArray['square_foot'] = areaFromValue / 107600.0;
            AreaArray['square_inch']= parseFloat(String(areaFromValue)) * 1.55e+7;
            AreaArray['square_acre']= areaFromValue * 2.471;
        }
        else if(areaFromUnit === 'square_mile')
        {
            AreaArray['square_meter']= areaFromValue * 2.59e+6;
            AreaArray['square_kilometer']= areaFromValue * 2.59;
            AreaArray['square_centimeter'] = areaFromValue * 2.59e+10;
            AreaArray['square_millimeter']= areaFromValue * 1e+10;
            AreaArray['square_micrometer']= areaFromValue * 2.59e+18;
            AreaArray['hectare'] = areaFromValue * 259;
            AreaArray['square_mile']= areaFromValue;
            AreaArray['square_yard']= areaFromValue * 3.098e+6;
            AreaArray['square_foot'] = areaFromValue * 2.788e+7;
            AreaArray['square_inch']= areaFromValue * 4.014e+9;
            AreaArray['square_acre']= areaFromValue * 640;
        }
        else if(areaFromUnit === 'square_yard')
        {
            AreaArray['square_meter']= areaFromValue / 1.196;
            AreaArray['square_kilometer']= areaFromValue / 1.196e+6;
            AreaArray['square_centimeter'] = areaFromValue * 8361;
            AreaArray['square_millimeter']= areaFromValue * 836100;
            AreaArray['square_micrometer']= areaFromValue * 8.361e+11;
            AreaArray['hectare'] = areaFromValue / 11960.0;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 3.098e+6;
            AreaArray['square_yard']= areaFromValue;
            AreaArray['square_foot'] = areaFromValue * 9;
            AreaArray['square_inch']= areaFromValue * 1296;
            AreaArray['square_acre']= areaFromValue / 4840.0;
        }
        else if(areaFromUnit === 'square_foot')
        {
            AreaArray['square_meter']= areaFromValue / 10.764;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1.076e+7;
            AreaArray['square_centimeter'] = areaFromValue * 929;
            AreaArray['square_millimeter']= areaFromValue * 92900;
            AreaArray['square_micrometer']= parseFloat(String(areaFromValue)) * 9.29e+10;
            AreaArray['hectare'] = areaFromValue / 107600.0;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 2.788e+7;
            AreaArray['square_yard']= areaFromValue / 9.0;
            AreaArray['square_foot'] = areaFromValue;
            AreaArray['square_inch']= areaFromValue / 144.0;
            AreaArray['square_acre']= areaFromValue / 43560.0;
        }
        else if(areaFromUnit === 'square_inch')
        {
            AreaArray['square_meter']= areaFromValue / 1550.0;
            AreaArray['square_kilometer']= parseFloat(String(areaFromValue)) / 1.55e+9;
            AreaArray['square_centimeter'] = parseFloat(String(areaFromValue)) * 4.047e+7;
            AreaArray['square_millimeter']= areaFromValue * 645.2;
            AreaArray['square_micrometer']= parseFloat(String(areaFromValue)) * 6.452e+8;
            AreaArray['hectare'] = parseFloat(String(areaFromValue)) / 1.55e+7;
            AreaArray['square_mile']= parseFloat(String(areaFromValue)) / 4.014e+9;
            AreaArray['square_yard']= areaFromValue / 1296.0;
            AreaArray['square_foot'] = areaFromValue / 144.0;
            AreaArray['square_inch']= areaFromValue;
            AreaArray['square_acre']= parseFloat(String(areaFromValue)) / 6.273e+6;
        }
        else if(areaFromUnit === 'square_acre')
        {
            AreaArray['square_meter'] = areaFromValue * 4047;
            AreaArray['square_kilometer']= areaFromValue / 247.1;
            AreaArray['square_centimeter'] = areaFromValue * 4.047e+7;
            AreaArray['square_millimeter']= areaFromValue * 4.047e+9;
            AreaArray['square_micrometer']= areaFromValue * 4.047e+15;
            AreaArray['hectare'] = areaFromValue / 2.471;
            AreaArray['square_mile']= areaFromValue * 640;
            AreaArray['square_yard']= areaFromValue * 4840;
            AreaArray['square_foot'] = areaFromValue * 43560;
            AreaArray['square_inch']= areaFromValue * 6.273e+6;
            AreaArray['square_acre']= areaFromValue;
        }
        setAreaToValue(AreaArray[areaToUnit]);
        handleClearTemprature();
        handleClickClearVolume();
        handleClickClearWeight();
        handleClickClearLength();
        setShowResult(true);
        setShowAreaResult(true);
    }

    function handleClickClearArea(){
        setShowResult(false);
        setAreaToUnit('square_kilometer');
        setAreaFromUnit('square_meter');
        setShowAreaResult(false);
        setErrorMessage('');
        setAreaFromValue(0);
        setAreaToValue(0);
        setAreaArray(getDefaultArea());
    }

    /**
     * Volume Conversion
     */

    const [volumeFromValue, setVolumeFromValue] = useState(0);
    const [volumeToValue, setVolumeToValue] = useState(0);
    const [volumeFromUnit, setVolumeFromUnit] = useState('cubic_meter');
    const [volumeToUnit, setVolumeToUnit] = useState('cubic_kilometer');
    const [showVolumeResult, setShowVolumeResult] = useState(false);
    const [VolumeArray, setVolumeArray] = useState(getDefaultVolume());
 
    function getDefaultVolume(){
        const temps = [];
        temps['cubic_meter']= 0;
        temps['cubic_kilometer']= 0;
        temps['cubic_centimeter'] = 0;
        temps['cubic_millimeter']= 0;
        temps['liter']= 0;
        temps['milliliter'] = 0;
        temps['us_gallon']= 0;
        temps['us_quart']= 0;
        temps['us_pint'] = 0;
        temps['us_cup']= 0;
        temps['us_fluid_ounce']= 0;
        temps['us_table_spoon']= 0;
        temps['us_tea_spoon']= 0;
        temps['imperial_gallon']= 0;
        temps['imperial_quart']= 0;
        temps['imperial_pint']= 0;
        temps['imperial_fluid_ounce']= 0;
        temps['imperial_table_spoon']= 0;
        temps['imperial_tea_spoon']= 0;
        temps['cubic_mile']= 0;
        temps['cubic_yard']= 0;
        temps['cubic_foot']= 0;
        temps['cubic_inch']= 0;
        return temps;
    }

    function handleChangeVolumeFromValue(e){
        setVolumeFromValue(e.target.value);
    }

    function handleChangeVolumeFromUnit(e){
        setVolumeFromUnit(e.target.value);
    }

    function handleChangeVolumeToUnit(e){
        setVolumeToUnit(e.target.value);
    }

    function handleClickCalculateVolume(){
        if(volumeFromUnit === 'cubic_meter')
        {
            VolumeArray['cubic_meter']= volumeFromValue;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1e+9;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 1e+6;
            VolumeArray['cubic_millimeter']= volumeFromValue * 1e+9;
            VolumeArray['liter']= volumeFromValue * 1000;
            VolumeArray['milliliter'] = volumeFromValue * 1e+6;
            VolumeArray['us_gallon']= volumeFromValue * 264.2;
            VolumeArray['us_quart']= volumeFromValue * 1057;
            VolumeArray['us_pint'] = volumeFromValue * 2113;
            VolumeArray['us_cup']= volumeFromValue * 4227;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 33810;
            VolumeArray['us_table_spoon']= volumeFromValue * 67630;
            VolumeArray['us_tea_spoon']= volumeFromValue * 202900;
            VolumeArray['imperial_gallon']= volumeFromValue * 220;
            VolumeArray['imperial_quart']= volumeFromValue * 879.9;
            VolumeArray['imperial_pint']= volumeFromValue * 1760; 
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 35200;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 56310;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 168900;
            VolumeArray['cubic_mile']= parseFloat(String(tempFromValue)) / 4.168e+9;
            VolumeArray['cubic_yard']= volumeFromValue * 1.308;
            VolumeArray['cubic_foot']= volumeFromValue * 35.315;
            VolumeArray['cubic_inch']= volumeFromValue * 61020;
        }
        else if(volumeFromUnit === 'cubic_kilometer')
        {
            VolumeArray['cubic_meter']= volumeFromValue * 1e+9;
            VolumeArray['cubic_kilometer']= volumeFromValue;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 1e+15;
            VolumeArray['cubic_millimeter']= volumeFromValue * 1e+18;
            VolumeArray['liter']= volumeFromValue * 1e+12;
            VolumeArray['milliliter'] = volumeFromValue * 1e+15;
            VolumeArray['us_gallon']= volumeFromValue * 2.642e+11;
            VolumeArray['us_quart']= volumeFromValue * 1.057e+12;
            VolumeArray['us_pint'] = volumeFromValue * 2.113e+12;
            VolumeArray['us_cup']= volumeFromValue * 4.227e+12;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 3.381e+13;
            VolumeArray['us_table_spoon']= volumeFromValue * 6.763e+13;
            VolumeArray['us_tea_spoon']= volumeFromValue * 2.029e+14;
            VolumeArray['imperial_gallon']= volumeFromValue *  2.2e+11;
            VolumeArray['imperial_quart']= volumeFromValue * 8.799e+11;
            VolumeArray['imperial_pint']= volumeFromValue * 1.76e+12;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue *  3.52e+13;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 5.631e+13;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 1.689e+14;
            VolumeArray['cubic_mile']= volumeFromValue / 4.168;
            VolumeArray['cubic_yard']= volumeFromValue * 1.308e+9;
            VolumeArray['cubic_foot']= volumeFromValue * 3.531e+10;
            VolumeArray['cubic_inch']= volumeFromValue * 6.102e+13;
        }
        else if(volumeFromUnit === 'cubic_centimeter')
        {
            VolumeArray['cubic_meter']= parseFloat(String(volumeFromValue)) / 1e+6;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1e+15;
            VolumeArray['cubic_centimeter'] = volumeFromValue;
            VolumeArray['cubic_millimeter']= volumeFromValue * 1000;
            VolumeArray['liter']= volumeFromValue / 1000;
            VolumeArray['milliliter'] = volumeFromValue * 1;
            VolumeArray['us_gallon']= volumeFromValue / 3785.0;
            VolumeArray['us_quart']= volumeFromValue / 946.4;
            VolumeArray['us_pint'] = volumeFromValue / 473.2;
            VolumeArray['us_cup']= volumeFromValue / 236.6;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 29.574;
            VolumeArray['us_table_spoon']= volumeFromValue / 14.787;
            VolumeArray['us_tea_spoon']= volumeFromValue / 4.929;
            VolumeArray['imperial_gallon']= volumeFromValue / 4546.0;
            VolumeArray['imperial_quart']= volumeFromValue / 1137.0;
            VolumeArray['imperial_pint']= volumeFromValue / 568.3;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 28.413;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 17.758;
            VolumeArray['imperial_tea_spoon']= volumeFromValue / 5.919;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 4.168e+15;
            VolumeArray['cubic_yard']= volumeFromValue / 764600.0;
            VolumeArray['cubic_foot']= volumeFromValue / 28320.0;
            VolumeArray['cubic_inch']= volumeFromValue / 16.387;
        }
        else if(volumeFromUnit === 'cubic_millimeter')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 1e+9;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1e+18;
            VolumeArray['cubic_centimeter'] = volumeFromValue / 1000.0;
            VolumeArray['cubic_millimeter']= volumeFromValue;
            VolumeArray['liter']= volumeFromValue / 1e+6;
            VolumeArray['milliliter'] = volumeFromValue / 1000.0;
            VolumeArray['us_gallon']= parseFloat(String(volumeFromValue)) / 3.785e+6;
            VolumeArray['us_quart']= volumeFromValue / 946400.0;
            VolumeArray['us_pint'] = volumeFromValue / 473200.0;
            VolumeArray['us_cup']= volumeFromValue / 236600.0;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 29570.0;
            VolumeArray['us_table_spoon']= volumeFromValue / 14790.0;
            VolumeArray['us_tea_spoon']= volumeFromValue / 4929.0;
            VolumeArray['imperial_gallon']= parseFloat(String(volumeFromValue)) / 4.546e+6;
            VolumeArray['imperial_quart']= volumeFromValue / 1.137e+6;
            VolumeArray['imperial_pint']= volumeFromValue / 568300.0;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 28410.0;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 17760.0;
            VolumeArray['imperial_tea_spoon']= volumeFromValue / 5919.0;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 4.168e+18;
            VolumeArray['cubic_yard']= parseFloat(String(volumeFromValue)) / 7.646e+8;
            VolumeArray['cubic_foot']= parseFloat(String(volumeFromValue)) / 2.832e+7;
            VolumeArray['cubic_inch']= volumeFromValue / 16390.0;
        }
        else if(volumeFromUnit === 'liter')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 1000.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1e+12;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 1000;
            VolumeArray['cubic_millimeter']= parseFloat(String(volumeFromValue)) *  1e+6;
            VolumeArray['liter']= volumeFromValue;
            VolumeArray['milliliter'] = volumeFromValue * 1000;
            VolumeArray['us_gallon']= volumeFromValue / 3.785;
            VolumeArray['us_quart']= volumeFromValue * 1.057;
            VolumeArray['us_pint'] = volumeFromValue * 2.113;
            VolumeArray['us_cup']= volumeFromValue * 4.227;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 33.814;
            VolumeArray['us_table_spoon']= volumeFromValue * 67.628;
            VolumeArray['us_tea_spoon']= volumeFromValue * 202.9;
            VolumeArray['imperial_gallon']= volumeFromValue / 4.546;
            VolumeArray['imperial_quart']= volumeFromValue * 1.136;
            VolumeArray['imperial_pint']= volumeFromValue * 1.76;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 35.195;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 56.312;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 168.9;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 4.168e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 764.6;
            VolumeArray['cubic_foot']= volumeFromValue / 28.317;
            VolumeArray['cubic_inch']= volumeFromValue * 61.024;
        }
        else if(volumeFromUnit === 'milliliter')
        {
            VolumeArray['cubic_meter']= parseFloat(String(volumeFromValue)) / 1e+6;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1e+15;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 1;
            VolumeArray['cubic_millimeter']= volumeFromValue * 1000;
            VolumeArray['liter']= volumeFromValue / 1000.0;
            VolumeArray['milliliter'] = volumeFromValue;
            VolumeArray['us_gallon']= volumeFromValue / 3785.0;
            VolumeArray['us_quart']= volumeFromValue / 946.4;
            VolumeArray['us_pint'] = volumeFromValue / 473.2;
            VolumeArray['us_cup']= volumeFromValue / 236.6;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 29.574;
            VolumeArray['us_table_spoon']= volumeFromValue / 14.787;
            VolumeArray['us_tea_spoon']= volumeFromValue / 4.929;
            VolumeArray['imperial_gallon']= volumeFromValue / 4546.0;
            VolumeArray['imperial_quart']= volumeFromValue / 1137.0;
            VolumeArray['imperial_pint']= volumeFromValue / 568.3;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 28.413;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 17.758;
            VolumeArray['imperial_tea_spoon']= volumeFromValue / 5.919;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 4.168e+15;
            VolumeArray['cubic_yard']= volumeFromValue / 764600.0;
            VolumeArray['cubic_foot']= volumeFromValue / 28320.0;
            VolumeArray['cubic_inch']= volumeFromValue / 16.387;
        }
        else if(volumeFromUnit === 'us_gallon')
        {  
            VolumeArray['cubic_meter']= volumeFromValue / 264.2;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 2.642e+11;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 3785;
            VolumeArray['cubic_millimeter']= parseFloat(String(volumeFromValue)) * 3.785e+6;
            VolumeArray['liter']= volumeFromValue * 3.785;
            VolumeArray['milliliter'] = volumeFromValue * 3785; 
            VolumeArray['us_gallon']= volumeFromValue;
            VolumeArray['us_quart']= volumeFromValue * 4;
            VolumeArray['us_pint'] = volumeFromValue * 8;
            VolumeArray['us_cup']= volumeFromValue * 16;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 128;
            VolumeArray['us_table_spoon']= volumeFromValue * 256;
            VolumeArray['us_tea_spoon']= volumeFromValue * 768;
            VolumeArray['imperial_gallon']= volumeFromValue / 1.201;
            VolumeArray['imperial_quart']= volumeFromValue * 3.331;
            VolumeArray['imperial_pint']= volumeFromValue * 6.661;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 133.2;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 213.2;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 639.5;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 1.101e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 202.0;
            VolumeArray['cubic_foot']= volumeFromValue / 7.48;
            VolumeArray['cubic_inch']= volumeFromValue * 231;
        }
        else if(volumeFromUnit === 'us_quart')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 1057.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1.057e+12;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 946.4;
            VolumeArray['cubic_millimeter']= volumeFromValue * 946400;
            VolumeArray['liter']= volumeFromValue / 1.057;
            VolumeArray['milliliter'] = volumeFromValue * 946.4;
            VolumeArray['us_gallon']= volumeFromValue / 4.0;
            VolumeArray['us_quart']= volumeFromValue;
            VolumeArray['us_pint'] = volumeFromValue * 2;
            VolumeArray['us_cup']= volumeFromValue * 4;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 32;
            VolumeArray['us_table_spoon']= volumeFromValue * 64;
            VolumeArray['us_tea_spoon']= volumeFromValue * 192;
            VolumeArray['imperial_gallon']= volumeFromValue / 4.804;
            VolumeArray['imperial_quart']= volumeFromValue / 1.201;
            VolumeArray['imperial_pint']= volumeFromValue * 1.665;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 33.307;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 53.291;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 159.9;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 4.404e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 807.9;
            VolumeArray['cubic_foot']= volumeFromValue / 29.922;
            VolumeArray['cubic_inch']= volumeFromValue * 57.75;
        }
        else if(volumeFromUnit === 'us_pint')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 2113.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 2.113e+12;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 473.2;
            VolumeArray['cubic_millimeter']= volumeFromValue * 473200;
            VolumeArray['liter']= volumeFromValue / 2.113;
            VolumeArray['milliliter'] = volumeFromValue * 473.2;
            VolumeArray['us_gallon']= volumeFromValue / 8.0;
            VolumeArray['us_quart']= volumeFromValue / 2.0;
            VolumeArray['us_pint'] = volumeFromValue;
            VolumeArray['us_cup']= volumeFromValue * 2;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 16;
            VolumeArray['us_table_spoon']= volumeFromValue * 32;
            VolumeArray['us_tea_spoon']= volumeFromValue * 96;
            VolumeArray['imperial_gallon']= volumeFromValue / 9.608;
            VolumeArray['imperial_quart']= volumeFromValue / 2.402;
            VolumeArray['imperial_pint']= volumeFromValue / 1.201;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 16.653;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 26.646;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 79.937;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 8.809e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 1616.0;
            VolumeArray['cubic_foot']= volumeFromValue / 59.844;
            VolumeArray['cubic_inch']= volumeFromValue * 28.875;
        }
        else if(volumeFromUnit === 'us_cup')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 4227.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 4.227e+12            ;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 236.6;
            VolumeArray['cubic_millimeter']= volumeFromValue * 236600;
            VolumeArray['liter']= volumeFromValue / 4.227;
            VolumeArray['milliliter'] = volumeFromValue * 236.6;
            VolumeArray['us_gallon']= volumeFromValue / 16.0;
            VolumeArray['us_quart']= volumeFromValue / 4.0;
            VolumeArray['us_pint'] = volumeFromValue / 2.0;
            VolumeArray['us_cup']= volumeFromValue;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 8;
            VolumeArray['us_table_spoon']= volumeFromValue * 16;
            VolumeArray['us_tea_spoon']= volumeFromValue * 48;
            VolumeArray['imperial_gallon']= volumeFromValue / 19.215;
            VolumeArray['imperial_quart']= volumeFromValue / 4.804;
            VolumeArray['imperial_pint']= volumeFromValue / 2.402;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 8.327;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 13.323;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 39.968;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 1.762e+13;
            VolumeArray['cubic_yard']= volumeFromValue / 3232.0;
            VolumeArray['cubic_foot']= volumeFromValue / 119.7;
            VolumeArray['cubic_inch']= volumeFromValue * 14.438;
        }
        else if(volumeFromUnit === 'us_fluid_ounce')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 33810.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) /  3.381e+13;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 29.574;
            VolumeArray['cubic_millimeter']= volumeFromValue * 29570;
            VolumeArray['liter']= volumeFromValue / 33.814;
            VolumeArray['milliliter'] = volumeFromValue * 29.574;
            VolumeArray['us_gallon']= volumeFromValue / 128.0;
            VolumeArray['us_quart']= volumeFromValue / 32.0;
            VolumeArray['us_pint'] = volumeFromValue / 16.0;
            VolumeArray['us_cup']= volumeFromValue / 8.0;
            VolumeArray['us_fluid_ounce']= volumeFromValue;
            VolumeArray['us_table_spoon']= volumeFromValue * 2;
            VolumeArray['us_tea_spoon']= volumeFromValue * 6;
            VolumeArray['imperial_gallon']= volumeFromValue / 153.7;
            VolumeArray['imperial_quart']= volumeFromValue / 38.43;
            VolumeArray['imperial_pint']= volumeFromValue / 19.215;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 1.0408423;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 1.665;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 4.996;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 1.409e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 25850.0;
            VolumeArray['cubic_foot']= volumeFromValue / 957.5;
            VolumeArray['cubic_inch']= volumeFromValue * 1.805;
        }
        else if(volumeFromUnit === 'us_table_spoon')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 67630.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 6.763e+13;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 14.787;
            VolumeArray['cubic_millimeter']= volumeFromValue / 67630.0;
            VolumeArray['liter']= volumeFromValue / 67.628;
            VolumeArray['milliliter'] = volumeFromValue * 14.787;
            VolumeArray['us_gallon']= volumeFromValue / 256.0;
            VolumeArray['us_quart']= volumeFromValue / 64.0;
            VolumeArray['us_pint'] = volumeFromValue / 32.0;
            VolumeArray['us_cup']= volumeFromValue / 16.0;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 2.0;
            VolumeArray['us_table_spoon']= volumeFromValue ;
            VolumeArray['us_tea_spoon']= volumeFromValue * 3;
            VolumeArray['imperial_gallon']= volumeFromValue / 307.4;
            VolumeArray['imperial_quart']= volumeFromValue / 76.861;
            VolumeArray['imperial_pint']= volumeFromValue / 38.43;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 1.922;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 1.201;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 2.498;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 2.819e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 51710.0;
            VolumeArray['cubic_foot']= volumeFromValue / 1915.0;
            VolumeArray['cubic_inch']= volumeFromValue / 1.108;
        }
        else if(volumeFromUnit === 'us_tea_spoon')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 202900.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 2.029e+14;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 4.929;
            VolumeArray['cubic_millimeter']= volumeFromValue * 4929;
            VolumeArray['liter']= volumeFromValue / 202.9;
            VolumeArray['milliliter'] = volumeFromValue * 4.929;
            VolumeArray['us_gallon']= volumeFromValue / 768.0;
            VolumeArray['us_quart']= volumeFromValue / 192.0;
            VolumeArray['us_pint'] = volumeFromValue / 96.0;
            VolumeArray['us_cup']= volumeFromValue / 48.0;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 6.0;
            VolumeArray['us_table_spoon']= volumeFromValue / 3.0;
            VolumeArray['us_tea_spoon']= volumeFromValue; 
            VolumeArray['imperial_gallon']= volumeFromValue / 922.3;
            VolumeArray['imperial_quart']= volumeFromValue / 230.6;
            VolumeArray['imperial_pint']= volumeFromValue / 115.3;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 5.765;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 3.603;
            VolumeArray['imperial_tea_spoon']= volumeFromValue / 1.201;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 8.457e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 155100.0;
            VolumeArray['cubic_foot']= volumeFromValue / 5745.0;
            VolumeArray['cubic_inch']= volumeFromValue / 3.325;
        }
        else if(volumeFromUnit === 'imperial_gallon')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 220.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) /  2.2e+11;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 4546;
            VolumeArray['cubic_millimeter']= parseFloat(String(volumeFromValue)) * 4.546e+6;
            VolumeArray['liter']= volumeFromValue * 4.546;
            VolumeArray['milliliter'] = volumeFromValue * 4546;
            VolumeArray['us_gallon']= volumeFromValue * 1.201;
            VolumeArray['us_quart']= volumeFromValue * 4.804;
            VolumeArray['us_pint'] = volumeFromValue * 9.608;
            VolumeArray['us_cup']= volumeFromValue * 19.215;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 153.7;
            VolumeArray['us_table_spoon']= volumeFromValue * 307.4;
            VolumeArray['us_tea_spoon']= volumeFromValue * 922.3;
            VolumeArray['imperial_gallon']= volumeFromValue ;
            VolumeArray['imperial_quart']= volumeFromValue * 4;
            VolumeArray['imperial_pint']= volumeFromValue * 8;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 160;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 256;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 768;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 9.169e+11;
            VolumeArray['cubic_yard']= volumeFromValue / 168.2;
            VolumeArray['cubic_foot']= volumeFromValue / 6.229;
            VolumeArray['cubic_inch']= volumeFromValue * 277.4;
        }
        else if(volumeFromUnit === 'imperial_quart')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 879.9;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 8.799e+11;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 1137;
            VolumeArray['cubic_millimeter']= volumeFromValue * 1.137e+6;
            VolumeArray['liter']= volumeFromValue * 1.137;
            VolumeArray['milliliter'] = volumeFromValue * 1137;
            VolumeArray['us_gallon']= volumeFromValue / 3.331;
            VolumeArray['us_quart']= volumeFromValue * 1.201;
            VolumeArray['us_pint'] = volumeFromValue * 2.402;
            VolumeArray['us_cup']= volumeFromValue * 4.804;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 38.43;
            VolumeArray['us_table_spoon']= volumeFromValue * 76.861;
            VolumeArray['us_tea_spoon']= volumeFromValue * 230.6; 
            VolumeArray['imperial_gallon']= volumeFromValue / 4.0;
            VolumeArray['imperial_quart']= volumeFromValue * 4;
            VolumeArray['imperial_pint']= volumeFromValue * 8;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 40;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 256;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 768;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 3.667e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 672.7; 
            VolumeArray['cubic_foot']= volumeFromValue / 6.229;
            VolumeArray['cubic_inch']= volumeFromValue * 277.4;
        }
        else if(volumeFromUnit === 'imperial_pint')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 1760;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1.76e+12;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 568.3;
            VolumeArray['cubic_millimeter']= volumeFromValue * 568300;
            VolumeArray['liter']= volumeFromValue / 1.76;
            VolumeArray['milliliter'] = volumeFromValue * 568.3;
            VolumeArray['us_gallon']= volumeFromValue / 6.661;
            VolumeArray['us_quart']= volumeFromValue / 1.665;
            VolumeArray['us_pint'] = volumeFromValue * 1.201;
            VolumeArray['us_cup']= volumeFromValue * 2.402;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 19.215;
            VolumeArray['us_table_spoon']= volumeFromValue * 38.43;
            VolumeArray['us_tea_spoon']= volumeFromValue * 115.3;
            VolumeArray['imperial_gallon']= volumeFromValue / 8.0;
            VolumeArray['imperial_quart']= volumeFromValue / 2.0;
            VolumeArray['imperial_pint']= volumeFromValue;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 20;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 32;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 96;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 7.335e+12;
            VolumeArray['cubic_yard']= volumeFromValue / 1345.0;
            VolumeArray['cubic_foot']= volumeFromValue / 49.831;
            VolumeArray['cubic_inch']= volumeFromValue * 34.677
            ;
        }
        else if(volumeFromUnit === 'imperial_fluid_ounce')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 35200.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 3.52e+13;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 28.413;
            VolumeArray['cubic_millimeter']= volumeFromValue * 28410;
            VolumeArray['liter']= volumeFromValue / 33.814;
            VolumeArray['milliliter'] = volumeFromValue * 29.574;
            VolumeArray['us_gallon']= volumeFromValue / 133.2;
            VolumeArray['us_quart']= volumeFromValue / 32.0;
            VolumeArray['us_pint'] = volumeFromValue / 16.0;
            VolumeArray['us_cup']= volumeFromValue / 8.327;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 1.0408423;
            VolumeArray['us_table_spoon']= volumeFromValue * 2;
            VolumeArray['us_tea_spoon']= volumeFromValue * 6;
            VolumeArray['imperial_gallon']= volumeFromValue / 153.7;
            VolumeArray['imperial_quart']= volumeFromValue / 38.43;
            VolumeArray['imperial_pint']= volumeFromValue / 19.215;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 1.665;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 4.996;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 1.467e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 26910.0;
            VolumeArray['cubic_foot']= volumeFromValue / 957.5;
            VolumeArray['cubic_inch']= volumeFromValue * 1.805;
        }
        else if(volumeFromUnit === 'imperial_table_spoon')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 56310.0;
            VolumeArray['cubic_kilometer']= volumeFromValue * 5.631e+13;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 17.758;
            VolumeArray['cubic_millimeter']= volumeFromValue / 17760.0;
            VolumeArray['liter']= volumeFromValue / 56.312;
            VolumeArray['milliliter'] = volumeFromValue * 17.758;
            VolumeArray['us_gallon']= volumeFromValue / 213.2;
            VolumeArray['us_quart']= volumeFromValue / 53.291;
            VolumeArray['us_pint'] = volumeFromValue / 26.646;
            VolumeArray['us_cup']= volumeFromValue / 13.323;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 1.665;
            VolumeArray['us_table_spoon']= volumeFromValue * 1.201;
            VolumeArray['us_tea_spoon']= volumeFromValue * 3.603;
            VolumeArray['imperial_gallon']= volumeFromValue / 256.0;
            VolumeArray['imperial_quart']= volumeFromValue / 64.0;
            VolumeArray['imperial_pint']= volumeFromValue / 32.0;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 1.6;
            VolumeArray['imperial_table_spoon']= volumeFromValue;
            VolumeArray['imperial_tea_spoon']= volumeFromValue / 3;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) /  2.347e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 43050.0;
            VolumeArray['cubic_foot']= volumeFromValue / 1595.0;
            VolumeArray['cubic_inch']= volumeFromValue * 1.084;
        }
        else if(volumeFromUnit === 'imperial_tea_spoon')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 168900.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1.689e+14;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 5.919;
            VolumeArray['cubic_millimeter']= volumeFromValue * 5919;
            VolumeArray['liter']= volumeFromValue / 168.9;
            VolumeArray['milliliter'] = volumeFromValue * 5.919;
            VolumeArray['us_gallon']= volumeFromValue / 639.5;
            VolumeArray['us_quart']= volumeFromValue / 159.9;
            VolumeArray['us_pint'] = volumeFromValue / 79.937;
            VolumeArray['us_cup']= volumeFromValue / 39.968;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 4.996;
            VolumeArray['us_table_spoon']= volumeFromValue / 2.498;
            VolumeArray['us_tea_spoon']= volumeFromValue * 1.201;
            VolumeArray['imperial_gallon']= volumeFromValue / 768.0;
            VolumeArray['imperial_quart']= volumeFromValue / 192.0;
            VolumeArray['imperial_pint']= volumeFromValue / 96.0;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 4.8;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 3.0;
            VolumeArray['imperial_tea_spoon']= volumeFromValue ;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 7.042e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 129200.0;
            VolumeArray['cubic_foot']= volumeFromValue / 4784.0;
            VolumeArray['cubic_inch']= volumeFromValue / 2.768;
        }
        else if(volumeFromUnit === 'cubic_mile')
        {
            VolumeArray['cubic_meter']= volumeFromValue * 4.168e+9;
            VolumeArray['cubic_kilometer']= volumeFromValue * 4.168;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 4.168e+15;
            VolumeArray['cubic_millimeter']= volumeFromValue * 4.168e+18;
            VolumeArray['liter']= volumeFromValue * 4.168e+12;
            VolumeArray['milliliter'] = volumeFromValue * 4.168e+15;
            VolumeArray['us_gallon']= volumeFromValue * 1.101e+12;
            VolumeArray['us_quart']= volumeFromValue * 4.404e+12;
            VolumeArray['us_pint'] = volumeFromValue * 8.809e+12;
            VolumeArray['us_cup']= volumeFromValue * 1.762e+13;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 1.409e+14; 
            VolumeArray['us_table_spoon']= volumeFromValue * 2.819e+14;
            VolumeArray['us_tea_spoon']= volumeFromValue * 8.457e+14;
            VolumeArray['imperial_gallon']= volumeFromValue * 9.169e+11;
            VolumeArray['imperial_quart']= volumeFromValue * 3.667e+12;
            VolumeArray['imperial_pint']= volumeFromValue * 7.335e+12;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 1.467e+14;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 2.347e+14;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 7.042e+14;
            VolumeArray['cubic_mile']= volumeFromValue;
            VolumeArray['cubic_yard']= volumeFromValue * 5.452e+9;
            VolumeArray['cubic_foot']= volumeFromValue * 1.472e+11;
            VolumeArray['cubic_inch']= volumeFromValue * 2.544e+14;
        }
        else if(volumeFromUnit === 'cubic_yard')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 1.308;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 1.308e+9;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 764600;
            VolumeArray['cubic_millimeter']= parseFloat(String(volumeFromValue)) * 7.646e+8;
            VolumeArray['liter']= volumeFromValue * 764.6;
            VolumeArray['milliliter'] = volumeFromValue * 764600;
            VolumeArray['us_gallon']= volumeFromValue * 201.974;
            VolumeArray['us_quart']= volumeFromValue * 807.9;
            VolumeArray['us_pint'] = volumeFromValue * 1616;
            VolumeArray['us_cup']= volumeFromValue * 3231.58;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 25850;
            VolumeArray['us_table_spoon']= volumeFromValue * 51710;
            VolumeArray['us_tea_spoon']= volumeFromValue * 155100;
            VolumeArray['imperial_gallon']= volumeFromValue * 168.2;
            VolumeArray['imperial_quart']= volumeFromValue * 672.7;
            VolumeArray['imperial_pint']= volumeFromValue * 1345;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 26910;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 43050;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 129200;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 5.452e+9;
            VolumeArray['cubic_yard']= volumeFromValue;
            VolumeArray['cubic_foot']= volumeFromValue * 27;
            VolumeArray['cubic_inch']= volumeFromValue * 46660;
        }
        else if(volumeFromUnit === 'cubic_foot')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 35.315;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 3.531e+10;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 28320;
            VolumeArray['cubic_millimeter']= volumeFromValue * 2.832e+7;
            VolumeArray['liter']= volumeFromValue * 28.317;
            VolumeArray['milliliter'] = volumeFromValue * 28320;
            VolumeArray['us_gallon']= volumeFromValue * 7.481;
            VolumeArray['us_quart']= volumeFromValue * 29.922;
            VolumeArray['us_pint'] = volumeFromValue * 59.844;
            VolumeArray['us_cup']= volumeFromValue * 119.7;
            VolumeArray['us_fluid_ounce']= volumeFromValue * 957.5;
            VolumeArray['us_table_spoon']= volumeFromValue * 1915;
            VolumeArray['us_tea_spoon']= volumeFromValue * 5745;
            VolumeArray['imperial_gallon']= volumeFromValue * 6.229;
            VolumeArray['imperial_quart']= volumeFromValue * 24.915;
            VolumeArray['imperial_pint']= volumeFromValue * 49.831;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue * 996.6;
            VolumeArray['imperial_table_spoon']= volumeFromValue * 1595;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 4784;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 2.544e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 27.0;
            VolumeArray['cubic_foot']= volumeFromValue;
            VolumeArray['cubic_inch']= volumeFromValue * 1728; 
        }
        else if(volumeFromUnit === 'cubic_inch')
        {
            VolumeArray['cubic_meter']= volumeFromValue / 61020.0;
            VolumeArray['cubic_kilometer']= parseFloat(String(volumeFromValue)) / 6.102e+13;
            VolumeArray['cubic_centimeter'] = volumeFromValue * 16.387;
            VolumeArray['cubic_millimeter']= volumeFromValue * 16390;
            VolumeArray['liter']= volumeFromValue / 61.024;
            VolumeArray['milliliter'] = volumeFromValue * 16.387;
            VolumeArray['us_gallon']= volumeFromValue / 231.0;
            VolumeArray['us_quart']= volumeFromValue / 57.75;
            VolumeArray['us_pint'] = volumeFromValue / 28.875;
            VolumeArray['us_cup']= volumeFromValue / 14.438;
            VolumeArray['us_fluid_ounce']= volumeFromValue / 1.805;
            VolumeArray['us_table_spoon']= volumeFromValue / 1.108;
            VolumeArray['us_tea_spoon']= volumeFromValue * 3.325;
            VolumeArray['imperial_gallon']= volumeFromValue / 277.4;
            VolumeArray['imperial_quart']= volumeFromValue / 69.355;
            VolumeArray['imperial_pint']= volumeFromValue / 34.677;
            VolumeArray['imperial_fluid_ounce']= volumeFromValue / 1.734;
            VolumeArray['imperial_table_spoon']= volumeFromValue / 1.084;
            VolumeArray['imperial_tea_spoon']= volumeFromValue * 2.768;
            VolumeArray['cubic_mile']= parseFloat(String(volumeFromValue)) / 2.544e+14;
            VolumeArray['cubic_yard']= volumeFromValue / 46660.0;
            VolumeArray['cubic_foot']= volumeFromValue / 1728.0;
            VolumeArray['cubic_inch']= volumeFromValue;
        }
        setVolumeToValue(VolumeArray[volumeToUnit]);
        handleClearTemprature();
        handleClickClearArea();
        handleClickClearWeight();
        handleClickClearLength();
        setShowResult(true);
        setShowVolumeResult(true);
    }

    function handleClickClearVolume(){
        setShowResult(false);
        setVolumeToUnit('cubic_kilometer');
        setVolumeFromUnit('cubic_meter');
        setShowVolumeResult(false);
        setErrorMessage('');
        setVolumeFromValue(0);
        setVolumeToValue(0);
        setVolumeArray(getDefaultVolume());
    }
    
    /**
     * Weight Conversion
     */

    const [weightFromValue, setWeightFromValue] = useState(0);
    const [weightToValue, setWeightToValue] = useState(0);
    const [weightFromUnit, setWeightFromUnit] = useState('kilogram');
    const [weightToUnit, setWeightToUnit] = useState('gram');
    const [showWeightResult, setShowWeightResult] = useState(false);
    const [WeightArray, setWeightArray] = useState(getDefaultWeight());

    function getDefaultWeight(){
        const temps = [];
        temps['kilogram']= 0;
        temps['gram']= 0;
        temps['milligram'] = 0;
        temps['metric_ton']= 0;
        temps['long_ton']= 0;
        temps['short_ton'] = 0;
        temps['pound']= 0;
        temps['ounce']= 0;
        temps['carrat'] = 0;
        temps['atomic_mass_unit']= 0;
        return temps;
    }

    function handleChangeWeightFromValue(e){
        setWeightFromValue(e.target.value);
    }

    function handleChangeWeightFromUnit(e){
        setWeightFromUnit(e.target.value);
    }

    function handleChangeWeightToUnit(e){
        setWeightToUnit(e.target.value);
    }

    function handleClickCalculateWeight(){
        if(weightFromUnit === 'kilogram')
        {
            WeightArray['kilogram']= weightFromValue;
            WeightArray['gram']= weightFromValue * 1000;
            WeightArray['milligram'] = parseFloat(String(weightFromValue)) * 1e+6;
            WeightArray['metric_ton']= weightFromValue / 1000.0;
            WeightArray['long_ton']= weightFromValue / 1016.0;
            WeightArray['short_ton'] = weightFromValue / 907.2;
            WeightArray['pound']= weightFromValue *  2.205;
            WeightArray['ounce']= weightFromValue * 35.274;
            WeightArray['carrat'] = weightFromValue * 5000;
            WeightArray['atomic_mass_unit']= weightFromValue * 6.022e+26;
        }
        else if(weightFromUnit === 'gram')
        {
            WeightArray['kilogram']= weightFromValue / 1000.0;
            WeightArray['gram']= weightFromValue;
            WeightArray['milligram'] = weightFromValue * 1000;
            WeightArray['metric_ton']= parseFloat(String(weightFromValue)) / 1e+6;
            WeightArray['long_ton']= parseFloat(String(weightFromValue)) / 1.016e+6;
            WeightArray['short_ton'] = weightFromValue / 907200.0;
            WeightArray['pound']= weightFromValue / 453.6;
            WeightArray['ounce']= weightFromValue / 28.35;
            WeightArray['carrat'] = weightFromValue * 5;
            WeightArray['atomic_mass_unit']= weightFromValue *  6.022e+23;
        }
        else if(weightFromUnit === 'milligram')
        {
            WeightArray['kilogram']= parseFloat(String(weightFromValue)) / 1e+6;
            WeightArray['gram']= weightFromValue / 1000.0;
            WeightArray['milligram'] = weightFromValue;          
            WeightArray['metric_ton']= parseFloat(String(weightFromValue)) / 1e+9;
            WeightArray['long_ton']= parseFloat(String(weightFromValue)) / 1.016e+9;
            WeightArray['short_ton'] = parseFloat(String(weightFromValue)) / 9.072e+8;
            WeightArray['pound']= weightFromValue / 453600.0;
            WeightArray['ounce']= weightFromValue / 28350.0;
            WeightArray['carrat'] = weightFromValue / 200.0;
            WeightArray['atomic_mass_unit']= weightFromValue * 6.022e+20;
        }
        else if(weightFromUnit === 'metric_ton')
        {
            WeightArray['kilogram']= weightFromValue * 1000;
            WeightArray['gram']= weightFromValue * 1e+6;
            WeightArray['milligram'] = weightFromValue * 1e+9;
            WeightArray['metric_ton']= weightFromValue;
            WeightArray['long_ton']= parseFloat(String(weightFromValue)) / 1.016e+9;
            WeightArray['short_ton'] = parseFloat(String(weightFromValue)) / 9.072e+8;
            WeightArray['pound']= weightFromValue *  2205;
            WeightArray['ounce']= weightFromValue * 35270;
            WeightArray['carrat'] = weightFromValue *  5e+6;
            WeightArray['atomic_mass_unit']= weightFromValue * 6.022e+29;
        }
        else if(weightFromUnit === 'long_ton')
        {
            WeightArray['kilogram']= weightFromValue * 1016;
            WeightArray['gram']= weightFromValue * 1.016e+6;
            WeightArray['milligram'] = weightFromValue * 1.016e+9;
            WeightArray['metric_ton']= weightFromValue * 1.016;
            WeightArray['long_ton']= weightFromValue ;
            WeightArray['short_ton'] = weightFromValue * 1.12;
            WeightArray['pound']= weightFromValue * 2240;
            WeightArray['ounce']= weightFromValue * 35840;
            WeightArray['carrat'] = weightFromValue * 5.08e+6;
            WeightArray['atomic_mass_unit']= weightFromValue * 6.119e+29;
        }
        else if(weightFromUnit === 'short_ton')
        {
            WeightArray['kilogram']= weightFromValue * 907.2;
            WeightArray['gram']= weightFromValue * 907200;
            WeightArray['milligram'] = parseFloat(String(weightFromValue)) * 9.072e+8;
            WeightArray['metric_ton']= weightFromValue / 1.102;
            WeightArray['long_ton']= weightFromValue / 1.12;
            WeightArray['short_ton'] = weightFromValue;
            WeightArray['pound']= weightFromValue * 2000;
            WeightArray['ounce']= weightFromValue * 32000;
            WeightArray['carrat'] = weightFromValue * 4.536e+6;
            WeightArray['atomic_mass_unit']= weightFromValue * 5.463e+29;
        }
        else if(weightFromUnit === 'pound')
        {
            WeightArray['kilogram']= weightFromValue / 2.205;
            WeightArray['gram']= weightFromValue * 453.6;
            WeightArray['milligram'] = weightFromValue * 453600;
            WeightArray['metric_ton']= weightFromValue / 2205.0;
            WeightArray['long_ton']= weightFromValue / 2240.0;
            WeightArray['short_ton'] = weightFromValue / 2000.0;
            WeightArray['pound']= weightFromValue;
            WeightArray['ounce']= weightFromValue * 16;
            WeightArray['carrat'] = weightFromValue * 2268;
            WeightArray['atomic_mass_unit']= weightFromValue * 2.732e+26;
        }
        else if(weightFromUnit === 'ounce')
        {
            WeightArray['kilogram']= weightFromValue / 35.274;
            WeightArray['gram']= weightFromValue * 28.35;
            WeightArray['milligram'] = weightFromValue * 28350;
            WeightArray['metric_ton']= weightFromValue / 35270.0;
            WeightArray['long_ton']= weightFromValue / 35840.0;
            WeightArray['short_ton'] = weightFromValue / 32000.0;
            WeightArray['pound']= weightFromValue / 16.0;
            WeightArray['ounce']= weightFromValue;
            WeightArray['carrat'] = weightFromValue * 141.7;
            WeightArray['atomic_mass_unit']= weightFromValue * 1.707e+25
            ;
        }
        else if(weightFromUnit === 'carrat')
        {
            WeightArray['kilogram']= weightFromValue / 5000.0;
            WeightArray['gram']= weightFromValue / 5.0;
            WeightArray['milligram'] = weightFromValue * 200.0;
            WeightArray['metric_ton']= parseFloat(String(weightFromValue)) / 5e+6;
            WeightArray['long_ton']= parseFloat(String(weightFromValue)) / 5.08e+6;
            WeightArray['short_ton'] = parseFloat(String(weightFromValue)) / 4.536e+6;
            WeightArray['pound']= weightFromValue / 2268.0;
            WeightArray['ounce']= weightFromValue / 141.7;
            WeightArray['carrat'] = weightFromValue;
            WeightArray['atomic_mass_unit']= weightFromValue * 1.204e+23;
        }
        else if(weightFromUnit === 'atomic_mass_unit')
        {
            WeightArray['kilogram']= parseFloat(String(weightFromValue)) / 6.022e+26;
            WeightArray['gram']= parseFloat(String(weightFromValue)) / 6.022e+23;
            WeightArray['milligram'] = parseFloat(String(weightFromValue)) / 6.022e+20;
            WeightArray['metric_ton']= parseFloat(String(weightFromValue)) / 6.022e+29;
            WeightArray['long_ton']= parseFloat(String(weightFromValue)) / 6.119e+29;
            WeightArray['short_ton'] = parseFloat(String(weightFromValue)) / 5.463e+29;
            WeightArray['pound']= parseFloat(String(weightFromValue)) / 2.732e+26;
            WeightArray['ounce']= parseFloat(String(weightFromValue)) / 1.707e+25;
            WeightArray['carrat'] = parseFloat(String(weightFromValue)) / 1.204e+23;
            WeightArray['atomic_mass_unit']= weightFromValue;
        }
        setWeightToValue(WeightArray[weightToUnit]);
        handleClearTemprature();
        handleClickClearLength();
        handleClickClearVolume();
        handleClickClearArea();
        setShowResult(true);
        setShowWeightResult(true);
    }
    
    function handleClickClearWeight(){
        setShowResult(false);
        setWeightToUnit('kilogram');
        setWeightFromUnit('gram');
        setShowWeightResult(false);
        setErrorMessage('');
        setWeightFromValue(0);
        setWeightToValue(0);
        setWeightArray(getDefaultWeight());
        goToTop();
    }

    function capitalize(words)
    {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    return(
        <div className='top-100'>
            <div className="row justify-content-center">                
                <div className="col-md-8 mt-4">
                    <div id="accordion" >
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#length" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Length
                                    </h5>
                                </button>
                            </div>
                            <div id="length" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    { showResult && showLengthResult &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output d-flex">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>
                                                <div>
                                                    <p className='mb-1 pl-4 d-flex align-items-center justify-content-center'>
                                                        {lengthFromValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(lengthFromUnit)}  : {lengthToValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(lengthToUnit)} 
                                                        <CopyToClipboard text={lengthFromValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(lengthFromUnit) + ":" +  lengthToValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(lengthToUnit) + "."}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard>     
                                                    </p>
                                                </div>                    
                                            </div>
                                        </div>
                                    }                                     
                                    
                                    
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>From</label>
                                                <input type="number" value={lengthFromValue} onChange={handleChangeLengthFromValue} className="form-control"/>
                                                <select value={lengthFromUnit} onChange={handleChangeLengthFromUnit} className="form-control mt-2"  size={6}>
                                                    <option value="meter">Meter</option>
                                                    <option value="kilometer">Kilometer</option>
                                                    <option value="centimeter">Centimeter</option>
                                                    <option value="millimeter">Millimeter</option>
                                                    <option value="micrometer">Micrometer</option>
                                                    <option value="nanometer">Nanometer</option>
                                                    <option value="mile">Mile</option>
                                                    <option value="yerd">Yard</option>
                                                    <option value="foot">Foot</option>
                                                    <option value="inch">Inch</option>
                                                    <option value="lightYear">Light Year</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input type="number" value={lengthToValue.toLocaleString('en-US', {maximumFractionDigits:2})} className="form-control" readOnly/>
                                                <select value={lengthToUnit} onChange={handleChangeLengthToUnit} className="form-control mt-2" size={6}>
                                                    <option value="meter">Meter ({lengthArray['meter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="kilometer">Kilometer ({lengthArray['kilometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="centimeter">Centimeter ({lengthArray['centimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="millimeter">Millimeter ({lengthArray['millimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="micrometer">Micrometer ({lengthArray['micrometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="nanometer">Nanometer ({lengthArray['nanometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="mile">Mile ({lengthArray['mile'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="yerd">Yard ({lengthArray['yerd'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="foot">Foot ({lengthArray['foot'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="inch">Inch ({lengthArray['inch'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="lightYear">Light Year ({lengthArray['lightYear'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateLength} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearLength} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#temp" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Temperature
                                    </h5>
                                </button>
                            </div>
                            <div id="temp" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    { showResult && ShowTempResult &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output d-flex">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>  
                                                <div>
                                                    <p className='mb-1 pl-4 d-flex align-items-center justify-content-center'>
                                                        {tempFromValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(tempFromUnit)}  : {tempToValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(tempToUnit)}
                                                        <CopyToClipboard text={tempFromValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(tempFromUnit) + ":" +  tempToValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(tempToUnit) + "."}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard>
                                                    </p>  
                                                </div>    
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>From</label>
                                                <input type="number" value={tempFromValue} onChange={handleChangeTempFromValue} className="form-control"/>
                                                <select className="form-control mt-2" size={6} onChange={handleChangeTempFromUnit} value={tempFromUnit}>
                                                    <option value="celcius">Celcius</option>
                                                    <option value="kelvin">Kelvin</option>
                                                    <option value="fahrenheit">Fahrenheit</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input type="number" value={tempToValue} className="form-control" readOnly/>
                                                <select className="form-control mt-2"  size={6}  onChange={handleChangeTempToUnit} value={tempToUnit}>
                                                    <option value="celcius">Celcius ({tempArray['celcius'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="kelvin">Kelvin ({tempArray['kelvin'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="fahrenheit">Fahrenheit ({tempArray['fahrenheit'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateTemprature} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClearTemprature} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#area" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Area
                                    </h5>
                                </button>
                            </div>
                            <div id="area" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    { showResult && showAreaResult &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output d-flex">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>  
                                                <div>
                                                    <p className='mb-1 pl-4 d-flex align-items-center justify-content-center'>
                                                        {areaFromValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(areaFromUnit.replace('_',' '))}  : {areaToValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(areaToUnit.replace('_',' '))}
                                                        <CopyToClipboard text={areaFromValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(areaFromUnit) + ":" +  areaToValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(areaToUnit) + "."}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard> 
                                                    </p>   
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>From</label>
                                                <input type="number" className="form-control" value={areaFromValue} onChange={handleChangeAreaFromValue}/>
                                                <select className="form-control mt-2"  size={6}  value={areaFromUnit} onChange={handleChangeAreaFromUnit}>
                                                    <option value="square_meter">Square Meter</option>
                                                    <option value="square_kilometer">Square Kilometer</option>
                                                    <option value="square_centimeter">Square Centimeter</option>
                                                    <option value="square_millimeter">Square Millimeter</option>
                                                    <option value="square_micrometer">Square Micrometer</option>
                                                    <option value="hectare">Hectare</option>
                                                    <option value="square_mile">Square Mile</option>
                                                    <option value="square_yard">Square Yard</option>
                                                    <option value="square_foot">Square Foot</option>
                                                    <option value="square_inch">Square Inch</option>
                                                    <option value="square_acre">Acre</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input type="number" className="form-control" value={areaToValue.toLocaleString('en-US', {maximumFractionDigits:2})} readOnly/>
                                                <select className="form-control mt-2"  size={6} value={areaToUnit} onChange={handleChangeAreaToUnit}>
                                                    <option value="square_meter">Square Meter ({AreaArray['square_meter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_kilometer">Square Kilometer ({AreaArray['square_kilometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_centimeter">Square Centimeter ({AreaArray['square_centimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_millimeter">Square Millimeter ({AreaArray['square_millimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_micrometer">Square Micrometer ({AreaArray['square_micrometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="hectare">Hectare ({AreaArray['hectare'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_mile">Square Mile ({AreaArray['square_mile'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_yard">Square Yard ({AreaArray['square_yard'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_foot">Square Foot ({AreaArray['square_foot'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_inch">Square Inch ({AreaArray['square_inch'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="square_acre">Acre ({AreaArray['square_acre'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateArea} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearArea} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#volume" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Volume
                                    </h5>
                                </button>
                            </div>
                            <div id="volume" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    { showResult && showVolumeResult &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output d-flex">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>  
                                                <div>
                                                    <p className='mb-1 pl-4 d-flex align-items-center justify-content-center'>
                                                        {volumeFromValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(volumeFromUnit.replace('_',' '))} : {volumeToValue.toLocaleString('en-US', {maximumFractionDigits:2})}  {capitalize(volumeToUnit.replace('_',' '))} 
                                                        <CopyToClipboard text={volumeFromValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(volumeFromUnit) + ":" +  volumeToValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(volumeToUnit) + "."}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard> 
                                                    </p>   
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>From</label>
                                                <input type="number" className="form-control" value={volumeFromValue} onChange={handleChangeVolumeFromValue}/>
                                                <select className="form-control mt-2" size={6} value={volumeFromUnit} onChange={handleChangeVolumeFromUnit}>
                                                    <option value="cubic_meter">Cubic Meter</option>
                                                    <option value="cubic_kilometer">Cubic Kilometer</option>
                                                    <option value="cubic_centimeter">Cubic Centimeter</option>
                                                    <option value="cubic_millimeter">Cubic Millimeter</option>
                                                    <option value="liter">Liter</option>
                                                    <option value="milliliter">Milliliter</option>
                                                    <option value="us_gallon">US Gallon</option>
                                                    <option value="us_quart">US Quart</option>
                                                    <option value="us_pint">US Pint</option>
                                                    <option value="us_cup">US Cup</option>
                                                    <option value="us_fluid_ounce">US Fluid Ounce</option>
                                                    <option value="us_table_spoon">US Table Spoon</option>
                                                    <option value="us_tea_spoon">US Tea Spoon</option>
                                                    <option value="imperial_gallon">Imperial Gallon</option>
                                                    <option value="imperial_quart">Imperial Quart</option>
                                                    <option value="imperial_pint">Imperial Pint</option>
                                                    <option value="imperial_fluid_ounce">Imperial Fluid Ounce</option>
                                                    <option value="imperial_table_spoon">Imperial Table Spoon</option>
                                                    <option value="imperial_tea_spoon">Imperial Tea Spoon</option>
                                                    <option value="cubic_mile">Cubic Mile</option>
                                                    <option value="cubic_yard">Cubic Yard</option>
                                                    <option value="cubic_foot">Cubic Foot</option>
                                                    <option value="cubic_inch">Cubic Inch</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input type="number" className="form-control" value={volumeToValue.toLocaleString('en-US', {maximumFractionDigits:2})} readOnly/>
                                                <select className="form-control mt-2" size={6} value={volumeToUnit} onChange={handleChangeVolumeToUnit}>
                                                    <option value="cubic_meter">Cubic Meter ({VolumeArray['cubic_meter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_kilometer">Cubic Kilometer ({VolumeArray['cubic_kilometer'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_centimeter">Cubic Centimeter ({VolumeArray['cubic_centimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_millimeter">Cubic Millimeter ({VolumeArray['cubic_millimeter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="liter">Liter ({VolumeArray['liter']})</option>
                                                    <option value="milliliter">Milliliter ({VolumeArray['milliliter'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_gallon">US Gallon ({VolumeArray['us_gallon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_quart">US Quart ({VolumeArray['us_quart'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_pint">US Pint ({VolumeArray['us_pint'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_cup">US Cup ({VolumeArray['us_cup'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_fluid_ounce">US Fluid Ounce ({VolumeArray['us_fluid_ounce'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_table_spoon">US Table Spoon ({VolumeArray['us_table_spoon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="us_tea_spoon">US Tea Spoon ({VolumeArray['us_tea_spoon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_gallon">Imperial Gallon ({VolumeArray['imperial_gallon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_quart">Imperial Quart ({VolumeArray['imperial_quart'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_pint">Imperial Pint ({VolumeArray['imperial_pint'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_fluid_ounce">Imperial Fluid Ounce ({VolumeArray['imperial_fluid_ounce'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_table_spoon">Imperial Table Spoon ({VolumeArray['imperial_table_spoon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="imperial_tea_spoon">Imperial Tea Spoon ({VolumeArray['imperial_tea_spoon'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_mile">Cubic Mile ({VolumeArray['cubic_mile'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_yard">Cubic Yard ({VolumeArray['cubic_yard'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_foot">Cubic Foot ({VolumeArray['cubic_foot'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="cubic_inch">Cubic Inch ({VolumeArray['cubic_inch'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateVolume}  className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearVolume} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#weight" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Weight
                                    </h5>
                                </button>
                            </div>
                            <div id="weight" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    { showResult && showWeightResult &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output d-flex">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>  
                                                <div>
                                                    <p className='mb-1 pl-4 d-flex align-items-center justify-content-center'>
                                                        {weightFromValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(weightFromUnit.replace('_',' '))}  : {weightToValue.toLocaleString('en-US', {maximumFractionDigits:2})} {capitalize(weightToUnit.replace('_',' '))}
                                                        <CopyToClipboard text={weightFromValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(weightFromUnit) + ":" +  weightToValue.toLocaleString('en-US', {maximumFractionDigits:2}) + " " + capitalize(weightToUnit) + "."}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard>
                                                    </p>     
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>From</label>
                                                <input type="number" className="form-control" value={weightFromValue} onChange={handleChangeWeightFromValue}/>
                                                <select className="form-control mt-2" size={6} value={weightFromUnit} onChange={handleChangeWeightFromUnit}>
                                                    <option value="kilogram">Kilogram</option>
                                                    <option value="gram">Gram</option>
                                                    <option value="milligram">Milligram</option>
                                                    <option value="metric_ton">Metric Ton</option>
                                                    <option value="long_ton">Long Ton</option>
                                                    <option value="short_ton">Short Ton</option>
                                                    <option value="pound">Pound</option>
                                                    <option value="ounce">Ounce</option>
                                                    <option value="carrat">Carrat</option>
                                                    <option value="atomic_mass_unit">Atomic Mass Unit</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input type="number" className="form-control" value={weightToValue.toLocaleString('en-US', {maximumFractionDigits:2})} readOnly/>
                                                <select className="form-control mt-2" size={6} value={weightToUnit} onChange={handleChangeWeightToUnit}>
                                                    <option value="kilogram">Kilogram ({WeightArray['kilogram'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="gram">Gram ({WeightArray['gram'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="milligram">Milligram ({WeightArray['milligram'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="metric_ton">Metric Ton ({WeightArray['metric_ton'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="long_ton">Long Ton ({WeightArray['long_ton'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="short_ton">Short Ton ({WeightArray['short_ton'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="pound">Pound ({WeightArray['pound'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="ounce">Ounce ({WeightArray['ounce'].toLocaleString('en-US', {maximumFractionDigits:2})})</option>
                                                    <option value="carrat">Carrat ({WeightArray['carrat'].toLocaleString('en-US', {maximumFractionDigits:2}) })</option>
                                                    <option value="atomic_mass_unit">Atomic Mass Unit ({WeightArray['atomic_mass_unit']})</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateWeight} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={handleClickClearWeight} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        
    );
}

export default Conversion;