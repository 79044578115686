/* eslint-disable */
import {
    Link
} from "react-router-dom";
import React from 'react';


const MenuPageList = (props) => {

    const {extensionPages} = props;
    
    return ( 
        <div className="tab-contents">
            { extensionPages && 
                Object.keys(extensionPages).map(keyOuter => 
                {
                    let pageData = extensionPages[keyOuter];
                    return (
                        <Link key={pageData.page_id} to={`/${pageData.page_route}`} className="menus">
                            <div className="w-100 border">
                                <img src={pageData.icon_url} alt={pageData.page_name}/>
                                <h4>{pageData.page_name}</h4>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
     );
}
 
export default MenuPageList;