/* eslint-disable */
import React, { useState, ChangeEvent } from 'react';
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function AutoLoan(){

    const [errorMessage, setErrorMessage] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copyText, setCopyText] = useState('');

    /**
     * Total Price
     */

    const [autoPrice, setAutoPrice] = useState(25000.00);
    const [loanTerm, setLoanTerm] = useState(60);
    const [interestRate, setInterestRate] = useState(4.5);
    const [downPayment, setDownPayment] = useState(5000);
    const [tradeInValue, setTradeInValue] = useState(0);
    const [salexTax, setSalexTax] = useState(7);
    const [otherTax, setOtherTax] = useState<number>(300);
    const [isAllFeesInLoan, setAllFeesInLoan] = useState(false);
    const [showtotalPriceResult, setShowtotalPriceResult] = useState(false);
   
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [totalSalesTax, setTotalSalesTax] = useState(0);
    const [totalUpFrontPayment , setTotalUpFrontPayment] = useState(0);
    const [totalLoanPayment, setTotalLoanPayment] = useState(0);
    const [totalLoanIntrest, setTotalLoanIntrest] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const handleChangeOtherTax = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        setOtherTax(newValue);
    };

    function handleChangeAutoPrice(e){
        setAutoPrice(e.target.value);
    }

    function handleChangeLoanTerm(e){
        setLoanTerm(e.target.value);
    }

    function handleChangeIntrestRate(e){
        setInterestRate(e.target.value);
    }

    function handleChangeDownPayment(e){
        setDownPayment(e.target.value);
    }

    function handleChangeTradeInValue(e){
        setTradeInValue(e.target.value);
    }

    function handleChangeSalesTax(e){
        setSalexTax(e.target.value);
    }

    function handleChangeAllFeesInLoan(e){
        setAllFeesInLoan(e.target.value);
    }

    function handleClickCalculateAutoLoan()
    {
        let interestpermonth = interestRate / (100 * 12.0); 
        let totalLoanAmounts = parseFloat(String(autoPrice)) - parseFloat(String(downPayment));
        let monthlyPayments = (parseFloat(String(totalLoanAmounts)) / (((Math.pow(( 1 + interestpermonth),loanTerm)) - 1) / ( interestpermonth * (Math.pow(( 1 + interestpermonth),loanTerm )))));
        let totalSalesTax = autoPrice * (salexTax/100);
        let totalLoanPaidAmounts = (monthlyPayments * loanTerm);
        let totalInterestAmounts = (totalLoanPaidAmounts - totalLoanAmounts);
        let UpfrontPayments = parseFloat(String(downPayment)) + parseFloat(String(totalSalesTax)) + parseFloat(String(otherTax));
        let totalAmount = parseFloat(String(autoPrice)) + parseFloat(String(totalSalesTax)) + parseFloat(String(totalInterestAmounts)) + otherTax;
        setMonthlyPayment(monthlyPayments);
        setTotalSalesTax(totalSalesTax);
        setTotalLoanAmount(totalLoanAmounts);
        setTotalLoanIntrest(totalInterestAmounts);
        setTotalUpFrontPayment(UpfrontPayments);
        setTotalLoanPayment(totalLoanPaidAmounts);
        setTotalCost(totalAmount);
        handleClickClearMonthlyPayment();
        setShowResult(true);
        setShowtotalPriceResult(true);
        /**
         * copyText
         */ 
        let copyText =  "Monthly Pay: $" + monthlyPayments.toLocaleString('en-US', {maximumFractionDigits:2}) + 
        "; Total Loan Amount: $" + totalLoanAmounts.toLocaleString('en-US', {maximumFractionDigits:2}) + 
        "; Sale Tax: $" + totalSalesTax.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Upfront Payment: $" + UpfrontPayments.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Total of " + loanTerm + " Loan Payments: $ " + totalLoanAmounts.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Total Loan Interest: $" + totalInterestAmounts.toLocaleString('en-US', {maximumFractionDigits:2})+
        "; Total Cost (price, interest, tax, fees): $" + totalAmount.toLocaleString('en-US', {maximumFractionDigits:2}) + ".";
        setCopyText(copyText);
        goToTop();
    }
    
    function handleClickClearAutoLoan(){
        setAutoPrice(2500);
        setLoanTerm(60);
        setInterestRate(4.75);
        setDownPayment(5000);
        setTradeInValue(0);
        setSalexTax(7);
        setOtherTax(300);
        setAllFeesInLoan(false);
        setShowResult(false);
        setShowtotalPriceResult(false);
        setCopyText('');
    }

    /**
     * Monthly Payment
     */

    
    const [monthlyPay, setMonthlyPay] = useState(380.00);
    const [monthlyLoanTerm, setMonthlyLoanTerm] = useState(60);
    const [monthlyInterestRate, setMonthlyInterestRate] = useState(4.5);
    const [monthlyDownPayment, setMonthlyDownPayment] = useState(5000);
    const [monthlyTradeInValue, setMonthlyTradeInValue] = useState(0);
    const [monthlySaleTax, setMonthlySaleTax] = useState(7);
    const [monthlyOtherFee, setMonthlyOtherFee] = useState(300);
    // const [monthlySsAllFeesInLoan, setAllFeesInLoan] = useState(false);
    const [ShowMonthlytotalPriceResult, setShowMonthlytotalPriceResult] = useState(false);

    const [totalPayment, setTotalPayment] = useState(0);
    const [totalMonthlyLoanAmount, setTotalMonthlyLoanAmount] = useState(0);
    const [totalMonthlySalesTax, setTotalMonthlySalesTax] = useState(0);
    const [totalMonthlyUpFrontPayment , setTotalMonthlyUpFrontPayment] = useState(0);
    const [totalMonthlyLoanPayment, setTotalMonthlyLoanPayment] = useState(0);
    const [totalMonthlyLoanInterest, setTotalMonthlyLoanInterest] = useState(0);
    const [totalMonthlyCost, setTotalMonthlyCost] = useState(0);


    function handleChangeMonthlyPay(e){
         setMonthlyPayment(e.target.value);
    }
 
    function handleChangeMonthlyLoanTerm(e){
         setMonthlyLoanTerm(e.target.value);
    }
 
    function handleChangeMonthlyInterestRate(e){
         setMonthlyInterestRate(e.target.value);
    }
 
    function hanldeChangeMonthlyDownPayment(e){
         setMonthlyDownPayment(e.target.value);
    }
 
    function handleChangeMonthlyTradeInValue(e){
         setMonthlyTradeInValue(e.target.value);
    }
 
    function handleChangeMonthlySaleTax(e){
         setMonthlySaleTax(e.target.value);
    }
 
    function handleChangeMonthlyOtherFee(e){
         setMonthlyOtherFee(e.target.value);
    }
 
    function handleClickCalculateMonthlyPayment()
    {
        let monthlyInterestAmount = monthlyInterestRate / (100 * 12.0); 
        let totalPayments = ((monthlyPay * (((Math.pow(( 1 + monthlyInterestAmount),monthlyLoanTerm)) - 1) / ( monthlyInterestAmount * (Math.pow(( 1 + monthlyInterestAmount),monthlyLoanTerm ))))) + monthlyDownPayment);
        let totalMonthlyLoanAmounts = totalPayments - monthlyDownPayment;
        let totalMonthlySalesTax = (totalPayments * (monthlySaleTax/100));
        let monthlyUpfrontPayments = (parseFloat(String(monthlyDownPayment)) + parseFloat(String(totalMonthlySalesTax)) + parseFloat(String(monthlyOtherFee)));
        let monthlyloanPaidAmount = monthlyPay * monthlyLoanTerm;
        let monthlyInterestPayment = (monthlyloanPaidAmount - totalMonthlyLoanAmounts);
        let monthlyTotalCost = parseFloat(String(totalPayments)) + parseFloat(String(totalMonthlySalesTax)) + parseFloat(String(monthlyInterestPayment)) + monthlyOtherFee;
        setTotalPayment(totalPayments);
        setTotalMonthlyLoanAmount(totalMonthlyLoanAmounts);
        setTotalMonthlySalesTax(totalMonthlySalesTax);
        setTotalMonthlyUpFrontPayment(monthlyUpfrontPayments);
        setTotalMonthlyLoanPayment(monthlyloanPaidAmount);
        setTotalMonthlyLoanInterest(monthlyInterestPayment);
        setTotalMonthlyCost(monthlyTotalCost);
        handleClickClearAutoLoan();
        setShowResult(true);
        setShowtotalPriceResult(false);
        setShowMonthlytotalPriceResult(true);
        /**
         * copyText
         */ 
        let copyText =  "Vehicle Price: $" + totalPayments.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Total Loan Amount: $" + totalMonthlyLoanAmounts.toLocaleString('en-US', {maximumFractionDigits:2}) + 
        "; Sale Tax: $" + totalMonthlySalesTax.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Total of " + loanTerm + " Loan Payments: $" + totalMonthlyLoanAmounts.toLocaleString('en-US', {maximumFractionDigits:2}) +
        "; Total Loan Interest: $" + monthlyInterestPayment.toLocaleString('en-US', {maximumFractionDigits:2}) + 
        "; Total Cost (price, interest, tax, fees): $" + monthlyTotalCost.toLocaleString('en-US', {maximumFractionDigits:2});
        setCopyText(copyText);
        goToTop();
    }
     
 
    function handleClickClearMonthlyPayment(){
         setShowtotalPriceResult(false);
         setMonthlyPay(380.00);
         setMonthlyLoanTerm(60);
         setMonthlyInterestRate(4.75);
         setMonthlyDownPayment(5000);
         setMonthlyTradeInValue(0);
         setMonthlySaleTax(7);
         setMonthlyOtherFee(300);
         setShowResult(false);
         setShowMonthlytotalPriceResult(false);
         setCopyText('');
    }

    return(
        <div className='top-100'>
            <div className="row justify-content-center p-0">    
                <div className="col-md-8 mt-2">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#total_price" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                    Total Price
                                    </h5>
                                </button>
                            </div>
                            <div id="total_price" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {showResult && showtotalPriceResult && 
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>
                                                <div className=''>
                                                    <p className='mb-1 pl-4'>Monthly Pay: ${(monthlyPayment).toLocaleString('en-US', {maximumFractionDigits:2}) } </p>
                                                    <p className="mb-1 pl-4">Total Loan Amount: ${(totalLoanAmount).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Sale Tax: ${(totalSalesTax).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Upfront Payment: ${(totalUpFrontPayment).toLocaleString('en-US', {maximumFractionDigits:2})} </p>
                                                    <p className='mb-1 pl-4'>Total of {loanTerm} Loan Payments: ${(totalLoanPayment).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Total Loan Interest: ${(totalLoanIntrest).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Total Cost (price, interest, tax, fees): ${(totalCost).toLocaleString('en-US', {maximumFractionDigits:2})} </p>                    
                                                    <p>
                                                        <CopyToClipboard text={copyText}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4 mb-2">Copy</button>
                                                        </CopyToClipboard>
                                                    </p>  
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Auto Price</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={autoPrice} onChange={handleChangeAutoPrice} className="form-control col-md-12" name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Loan Term</label>
                                                <div className="input-group">
                                                    <input  type="text" value={loanTerm} onChange={handleChangeLoanTerm} className='form-control col-md-12' name="" id=""/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        months
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Interest Rate</label>
                                                <div className="input-group">
                                                    <input type="text" value={interestRate} onChange={handleChangeIntrestRate} className='form-control col-md-12' name="" id=""/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">percent</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Down Payment</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={downPayment} onChange={handleChangeDownPayment} className="form-control col-md-12" name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Trade-in Value</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={tradeInValue} onChange={handleChangeTradeInValue} className="form-control col-md-12" name="" id=""/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Your State</label>
                                                <select className="form-control">
                                                    <option>Country 1</option>
                                                    <option>Country 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Sales Tax</label>
                                                <div className="input-group">
                                                    <input type="text" value={salexTax} onChange={handleChangeSalesTax} className='form-control col-md-12' name="" id=""/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">percent</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Title, Registration & Other Fees</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={otherTax} onChange={handleChangeOtherTax} className="form-control col-md-12" name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <button onClick={handleClickCalculateAutoLoan} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6 col-md-6">
                                        <button onClick={handleClickClearAutoLoan} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#monthly_payment" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                    Monthly Payment
                                    </h5>
                                </button>
                            </div>
                            <div id="monthly_payment" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {showResult && ShowMonthlytotalPriceResult && 
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className="output">
                                                <h3 className='bg-warning px-4 mb-0'>Result</h3>
                                                <div className=''>
                                                    <p className='mb-1 pl-4'>Vehicle Price: $ {(totalPayment).toLocaleString('en-US', {maximumFractionDigits:2})} </p>
                                                    <p className="mb-1 pl-4">Total Loan Amount: ${(totalMonthlyLoanAmount).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Sale Tax: ${(totalMonthlySalesTax).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Upfront Payment: ${(totalMonthlyUpFrontPayment).toLocaleString('en-US', {maximumFractionDigits:2})} </p>
                                                    <p className='mb-1 pl-4'>Total of {loanTerm} Loan Payments: ${(totalMonthlyLoanPayment).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Total Loan Interest: ${(totalMonthlyLoanInterest).toLocaleString('en-US', {maximumFractionDigits:2})}</p>
                                                    <p className='mb-1 pl-4'>Total Cost (price, interest, tax, fees): ${(totalMonthlyCost).toLocaleString('en-US', {maximumFractionDigits:2})} </p>                         
                                                    <p >
                                                        <CopyToClipboard text={copyText}
                                                            onCopy={() => setCopied(true)}>
                                                            <button className="btn btn-dark ml-4 mb-2">Copy</button>
                                                        </CopyToClipboard>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Monthly Pay</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={monthlyPay} onChange={handleChangeMonthlyPay} className="form-control col-md-12" name="monthly_pay" id="monthly_pay"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Loan Term</label>
                                                <div className="input-group">
                                                    <input  type="text" value={monthlyLoanTerm} onChange={handleChangeMonthlyLoanTerm} className='form-control col-md-12' name="monthly_loan_term" id="monthly_loan_term"/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        months
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Interest Rate</label>
                                                <div className="input-group">
                                                    <input type="text" value={monthlyInterestRate} onChange={handleChangeMonthlyInterestRate} className='form-control col-md-12' name="" id=""/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">percent</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Down Payment</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={monthlyDownPayment} onChange={hanldeChangeMonthlyDownPayment} className="form-control col-md-12" name="monthly_down_payment" id="monthly_down_payment"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Trade-in Value</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={monthlyTradeInValue} onChange={handleChangeMonthlyTradeInValue} className="form-control col-md-12" name="monthly_tradein_value" id="monthly_tradein_value"/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Your State</label>
                                                <select className="form-control">
                                                    <option>Country 1</option>
                                                    <option>Country 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="form-group" >
                                                <label>Sales Tax</label>
                                                <div className="input-group">
                                                    <input type="text" value={monthlySaleTax} onChange={handleChangeMonthlySaleTax} className='form-control col-md-12' name="monthly_sale_tax" id="monthly_sale_tax"/>
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">percent</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group">
                                                <label>Title, Registration & Other Fees</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={monthlyOtherFee} onChange={handleChangeMonthlyOtherFee} className="form-control col-md-12" name="monthly_other_fee" id="monthly_other_fee"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateMonthlyPayment} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearMonthlyPayment} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default AutoLoan;