/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Hour()
{
    let todayTime = moment().format('HH:mm');
    const [noOfHours, setNoOfHours] = useState(0);
    const [noOfMinutes, setNoOfMinutes] = useState(0);
    const [startTime, setStartTime] = useState(todayTime);
    const [endTime, setEndTime] = useState(todayTime);
    const [errorMessage, setErrorMessage] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [copied, setCopied] = useState(false);

    function handleChangeStartTime(e){
        setStartTime(e.target.value);
    }

    function handleChangeEndTime(e){
        setEndTime(e.target.value);
    }

    function handleClickCalculate()
    {
        
        let timeStart = moment(startTime, "HH:mm");
        let timeEnd = moment(endTime, "HH:mm");
        console.log(moment(timeStart).minutes());
        if(moment(timeStart).hours() > moment(timeEnd).hours())
        {
            setNoOfHours(timeStart.diff(timeEnd, 'hours'));
        }
        else
        {
            setNoOfHours(timeEnd.diff(timeStart, 'hours'));
        }
        if(moment(timeStart).minutes() > moment(timeEnd).minutes())
        {
            //setNoOfMinutes(timeStart.diff(timeEnd, 'minutes'));
            setNoOfMinutes( moment(timeStart).minutes() - moment(timeEnd).minutes());
        }
        else
        {
            //setNoOfMinutes(timeEnd.diff(timeStart, 'minutes'));
            setNoOfMinutes(moment(timeEnd).minutes() - moment(timeStart).minutes());
        }
        setShowResult(true);
        goToTop();
    }

    function handleClickClear(){
        setShowResult(false);
        setStartTime(todayTime);
        setEndTime(todayTime);
        
    }

    return(
        <div className="top-100">
             <div id="hour" className="w-100">
                <div className="container">
                    <div className='row justify-content-center'>
                        {errorMessage &&
                            <div className='col-md-8'>
                                <div className="errorMessage">
                                    <p>{errorMessage}</p>
                                </div>
                            </div>
                        }
                        {showResult && 
                            <div className='col-md-8'>
                                <div className='output pb-1'>
                                    <h2 className='bg-warning pl-3'>Result</h2>
                                    <div className=''>
                                        <p className='mb-1 pl-4'><strong>{noOfHours} </strong> Hours <strong> {noOfMinutes} </strong> Minutes</p>
                                        <div className='col-6 col-md-6'>
                                            <CopyToClipboard text={noOfHours + " Hours " + noOfMinutes  + " Minutes."}
                                                    onCopy={() => setCopied(true)}>
                                                <button className="btn btn-dark btn-block">Copy</button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        }
                        <div className='col-md-8 mt-2 pl-4'>
                            <div className="form-group ">
                                <label>Start Time</label>
                                <input type="time" value={startTime} onChange={handleChangeStartTime} className="form-control col-md-12" name="dob" id="dob"/>

                            </div>
                        </div>
                        <div className="col-md-8 pl-4">
                            <div className='form-group'>
                                <label>End Time </label>
                                <input type="time" value={endTime} onChange={handleChangeEndTime} className='form-control col-md-12' name="new_date" id="new_date"/>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center pl-2'>
                        <div className='col-6 col-md-4'>
                            <button onClick={handleClickCalculate} className="btn btn-warning btn-block">Calculate</button>
                        </div>
                        <div className='col-6 col-md-4'>
                            <button onClick={handleClickClear} className='btn btn-dark btn-block'>Clear</button>
                        </div>
                        
                    </div>                    
                </div>
            </div>
        </div>
    );
}

export default Hour;