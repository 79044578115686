/* eslint-disable */
import React, { useState } from 'react';
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Salary(){
    
    const weekksInYear = 52;
    const monthsInYear = 12;
    const quaterInYear = 4;
    const biWeeksInMonth = 2;
    const semiMonths = 2;
    const [hourlyPay, setHourPay] = useState(0);
    const [dailyPay, setDailyPay] = useState(0);
    const [weekPay, setWeekPay] = useState(0);
    const [biWeekPay, setBiWeekPay] = useState(0);
    const [semiMonthPay, setSemiMonthPay] = useState(0);
    const [monthPay, setMonthPay] = useState(0);
    const [quaterPay, setQuaterPay] = useState(0);
    const [yearPay, setYearPay] = useState(0);
    const [salaryAmount, setSalaryAmount] = useState(30);
    const [hoursPerWeek, setHoursPerWeek] = useState(40);
    const [dayPerWeek, setDayPerWeek] = useState(5);
    const [salaryUnit, setSalaryUnit] = useState('hour');
    const [showResult, setShowResult] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [copied, setCopied] = useState(false);

    function handleChangeSalaryAmount(e){
        setSalaryAmount(parseFloat(e.target.value));
    }

    function handleChangeHoursPerWeek(e){
        setHoursPerWeek(parseInt(e.target.value));
    }

    function handleChangeDayPerWeek(e){
        setDayPerWeek(parseInt(e.target.value));
    }

    function handleChangeSalaryUnit(e){
        setSalaryUnit(e.target.value);
    }

    function handleClickCalculate()
    {
        let hoursPerDay = hoursPerWeek/dayPerWeek;
        let hoursPerYear = hoursPerWeek * weekksInYear; 
        let hoursPerMonth = hoursPerYear / monthsInYear; 
        if (salaryUnit === 'hour')
        {
            setHourPay(salaryAmount);
            setDailyPay(hoursPerDay * salaryAmount);
            setWeekPay(salaryAmount * hoursPerWeek);
            setBiWeekPay(salaryAmount * (hoursPerWeek * biWeeksInMonth));
            setMonthPay(hoursPerMonth * salaryAmount);
            setSemiMonthPay((hoursPerMonth/semiMonths)* salaryAmount);
            setQuaterPay((hoursPerYear/ quaterInYear) * salaryAmount);
            setYearPay(hoursPerYear * salaryAmount);
        }
        else if (salaryUnit === 'day')
        {
            //we are find per hour pay rest we will calculate from hour pay
            setDailyPay(salaryAmount);
            setHourPay(salaryAmount/hoursPerDay);
            setWeekPay(hourlyPay * hoursPerWeek);
            setBiWeekPay(weekPay * biWeeksInMonth);
            setMonthPay(hoursPerMonth * hourlyPay);
            setSemiMonthPay(monthPay/semiMonths);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);
        }
        else if (salaryUnit === 'week')
        {
            //we are find per hour pay rest we will calculate from hour pay
            setWeekPay(salaryAmount);
            setHourPay(salaryAmount/hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setBiWeekPay(weekPay * biWeeksInMonth);
            setMonthPay(hoursPerMonth * hourlyPay);
            setSemiMonthPay(monthPay/semiMonths);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);

        }
        else if (salaryUnit === 'biWeek')
        {
            setBiWeekPay(salaryAmount);
             //we are find per hour pay rest we will calculate from hour pay
            setWeekPay(biWeekPay/biWeeksInMonth);
            setHourPay(weekPay/hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setMonthPay(hoursPerMonth * hourlyPay);
            setSemiMonthPay(monthPay/semiMonths);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);
        }
        else if (salaryUnit === 'semiMonth')
        {
            setSemiMonthPay(salaryAmount);
            //we are find per hour pay rest we will calculate from hour pay
            setMonthPay(semiMonthPay * semiMonths);
            setHourPay(monthPay/hoursPerMonth);
            setWeekPay(hourlyPay * hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);
        }
        else if (salaryUnit == 'month')
        {
            setMonthPay(salaryAmount);
            //we are find per hour pay rest we will calculate from hour pay
            setSemiMonthPay(salaryAmount / semiMonths);
            setHourPay(monthPay/hoursPerMonth);
            setWeekPay(hourlyPay * hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);
        }
        else if (salaryUnit === 'quater')
        {
            setQuaterPay(salaryAmount);
            //we are find month pay rest we will calculate from hour pay
            setMonthPay(salaryAmount/quaterInYear);
            setSemiMonthPay(monthPay / semiMonths);
            setHourPay(monthPay/hoursPerMonth);
            setWeekPay(hourlyPay * hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setQuaterPay(monthPay * quaterInYear);
            setYearPay(monthPay * monthsInYear);
        }
        else if (salaryUnit === 'year')
        {
            setYearPay(salaryAmount);
            //we are find month pay rest we will calculate from hour pay
            setMonthPay(salaryAmount/monthsInYear);
            setSemiMonthPay(monthPay / semiMonths);
            setHourPay(monthPay/hoursPerMonth);
            setWeekPay(hourlyPay * hoursPerWeek);
            setDailyPay(hoursPerDay * hourlyPay);
            setQuaterPay(salaryAmount / quaterInYear);
        }
        setShowResult(true);
        goToTop();
    }

    function handleClickClear(){
        setShowResult(false);
        setSalaryAmount(30);
        setSalaryUnit('hour');
        setHoursPerWeek(40);
        setDayPerWeek(5);
    }

    return(
        <div className="top-100">
             <div id="salarycalc" className="w-100">
                <div className="container">
                    <div className="row justify-content-center">
                        {showResult && 
                            <div className="col-md-8">
                                <div className='output pb-1'>
                                    <h2 className='bg-warning pl-3'>Result</h2>
                                    <div className="table-responsive px-4 h-100">
                                        <table className='table table-bordered'>
                                            <tbody>
                                                <tr>
                                                    <th>Hourly</th>
                                                    <td>${hourlyPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Daily</th>
                                                    <td>${dailyPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <th>Weekly</th>
                                                    <td>${weekPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Bi-Weekly</th>
                                                    <td>${biWeekPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <th>Semi Monthly</th>
                                                    <td>${semiMonthPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Monthly</th>
                                                    <td>${monthPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quarterly</th>
                                                    <td>${quaterPay.toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Annual</th>
                                                    <td>
                                                        ${yearPay.toLocaleString('en-US', {maximumFractionDigits:2})}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Suggested Rent 30%</th>
                                                    <td>${(monthPay * 0.30).toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Suggested Auto Payment 10%</th>
                                                    <td>${(monthPay * 0.10).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Suggested Savings 10%</th>
                                                    <td>${(monthPay * 0.10).toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                    <th>Suggested Health Insurance 10%</th>
                                                    <td>${(monthPay * 0.10).toLocaleString('en-US', {maximumFractionDigits:2})}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-8 col-md-6'>
                                        <CopyToClipboard text={"$" + yearPay.toLocaleString('en-US', {maximumFractionDigits:2})}
                                            onCopy={() => setCopied(true)}>
                                            <button className="btn btn-dark btn-block">Copy Annual Salary</button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                
                            </div>
                        }
                        <div className='col-md-8 mt-2 pl-4'>
                            <div className="form-group">
                                <label>Salary per Unit</label>
                                <div className="input-group">
                                    <span className="input-group-addon bg-dark text-white pt-2  px-1"><i className="material-icons">attach_money</i></span>
                                    <input value={salaryAmount} onChange={handleChangeSalaryAmount} id="salary_amount" type="number" className="form-control" name="salary_amount" placeholder="Salary Amount"/>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8 pl-4'>
                            <div className="form-group">
                                <label>Salary Per Unit</label>
                                <select id="salaryUnit" className="form-control" value={salaryUnit} onChange={handleChangeSalaryUnit} aria-label="Default select example">
                                    <option value="hour">Hour</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="biWeek">Bi-Week</option>
                                    <option value="semiMonth">Semi-Month</option>
                                    <option value="month">Month</option>
                                    <option value="quarter">Quarter</option>
                                    <option value="year">Year</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center pl-2'>
                        <div className='col-6 col-md-4'>
                            <div className="form-group">
                                <label>Hours per Week</label>
                                <input value={hoursPerWeek} onChange={handleChangeHoursPerWeek} type="number" className='form-control'/>
                            </div>
                        </div>
                        <div className='col-6 col-md-4'>
                            <div className="form-group">
                                <label>Days per Week</label>
                                <input value={dayPerWeek} onChange={handleChangeDayPerWeek} type="number"  className='form-control'/>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>                        
                        <div className='col-6 col-md-4'>
                            <div className="form-group">
                                <button onClick={handleClickCalculate} className='btn btn-warning col-md-12' >Calculate</button>
                            </div>
                        </div>
                        <div className='col-6 col-md-4'>
                            <div className="form-group">
                                <button onClick={handleClickClear} className='btn btn-dark col-md-12' >Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Salary;