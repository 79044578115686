/* eslint-disable */
import React from 'react';
import Calc from './calc/calc';

function BasicCalculator()
{
    return(
        <div className='top-100'>
            <div className='row mt-2'>
                <div className='col-md-12 mt-4'>
                    <Calc />    
                </div>
            </div>
        </div>
            
    );
}

export default BasicCalculator;