/* eslint-disable */
import React from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import UploadButton from '../../../../common/UploadButton';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

function Converter() {

    const handleDownload = (uploadedFileName, imageUrl) => {
        const link = document.createElement('a');
        link.download = uploadedFileName;
        link.href = imageUrl;
        link.click();
    };

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const convertPdfToImage = async (event, type = '') => {
        const file = await event.target.files[0];
        const reader = new FileReader();
        let fileName = "image";
        reader.onloadend = () => {
            fileName = file.name.slice(0, file.name.lastIndexOf("."));
        };
        reader.readAsArrayBuffer(file);
        const data = await readFileData(file);
        const pdf = await pdfjsLib.getDocument(data).promise;
        const canvas = document.createElement("canvas");
        let height = 0;
        let width = 0;

        for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            height += viewport.height;
            width = Math.max(width, viewport.width);
        }

        canvas.width = width;
        canvas.height = height;
        let yOffset = 0;

        for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            context.translate(0, yOffset);
            const renderContext = {
                canvasContext: context,
                viewport
            };
            await page.render(renderContext).promise;
            yOffset += viewport.height;
        }

        if (type === 'jpg') {
            handleDownload(fileName, canvas.toDataURL("image/jpeg")); // convert to jpg
        } else {
            handleDownload(fileName, canvas.toDataURL());// convert to png
        }

        canvas.remove();
    };


    return (
        <>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <UploadButton
                        title="Convert PDF to JPG"
                        accept="application/pdf"
                        onChange={(e) => convertPdfToImage(e, 'jpg')}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <UploadButton
                        title="Convert PDF to PNG"
                        accept="application/pdf"
                        onChange={(e) => convertPdfToImage(e, 'png')}
                    />
                </div>
            </div>
        </>
    );
}
export default Converter;
