/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";
import { Document, Page, pdfjs } from 'react-pdf';
import UploadButton from '../../../../common/UploadButton';
import { PagingControl } from '../pdfComponents/PagingControl';
import DraggableSignature from './DraggableSignature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../pdfEditor.css';
import { 
  faDownload,
  faTimes,
  faSignature,
  faSearchPlus, 
  faSearchMinus,
  faTrash 
} from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function Signature() {
  const [pdfBytes, setPdfBytes] = useState(null);
  const [originalPdfBytes, setOriginalPdfBytes] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [modifiedPdf, setModifiedPdf] = useState(null);
  const [show, setShow] = useState(false);
  const [legalName, setLegalName] = useState(''); //legal name 
  const [signatureDate, setSignatureDate] = useState(dayjs().format("DD MMM YYYY")); //signature date
  const [signatureTime, setSignatureTime] = useState(dayjs().format("HH:mm:ss")); //signature time
  const [ipAddress, setIpAddress] = useState(); //IP address
  const [signatureURL, setSignatureURL] = useState(null);
  const [signatureDragableVisiable, setSignatureDragableVisiable] = useState(false);
  const [scale, setScale] = useState(1); //zoom in/out
  const [zoom, setZoom] = useState('');
  const sigRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setSignatureTime(dayjs().format('HH:mm:ss'));
    }, 1000); // Update the time every second

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);


  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const data = await file.arrayBuffer({ maxSize: 50 * 1024 * 1024 }); // Increase the max buffer size to 50 MB
    const pdfBytes = new Uint8Array(data);
    setPdfBytes(pdfBytes);
    setPdfUrl(URL.createObjectURL(file));
    setOriginalPdfBytes(pdfBytes);
  };

  const handleClickClear = () => {
    setPdfBytes(null);
    setPdfUrl('');
    setSignatureDragableVisiable(false);
    setOriginalPdfBytes(null);
  };

  const handleUpdateFile = (modifiedPdf) => {
    const blob = new Blob([modifiedPdf], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);
  }

  const handleDownloadClick = async () => {
    if (modifiedPdf) {
      const blob = new Blob([modifiedPdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      console.log(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = `annotation.pdf`;
      link.click();
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
    getIp();
  };

  useEffect(() => {
    getIp();
  }, [ipAddress]);

  async function getIp() {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    setIpAddress(data.ip);
  }

  const handleClearSignatureData = () => {
    setLegalName('');
    setSignatureURL('');
    if (sigRef.current) {
      sigRef.current.clear(); // Clear the canvas
    }
  }

  const handleDeleteAnnotatedText = async () => {
    if (modifiedPdf) {
      setModifiedPdf(originalPdfBytes);
      setPdfBytes(originalPdfBytes);
      handleUpdateFile(originalPdfBytes);
    }
  }

const handleZoomInClick = () => {
    setScale(scale + 0.2);
    setZoom('zoomed-in');
  };

  const handleZoomOutClick = () => {
    setScale(scale - 0.2);
    setZoom('');
  }

  const windowSize = useWindowSize();

  return (
    <>
      <div className="row justify-content-center" id="pdfeditor">
        {!pdfBytes ? (
          <div className="col-md-12 d-flex justify-content-center">
            <div className="row justify-content-center">
              <UploadButton
                title="Select PDF File"
                accept="application/pdf"
                onChange={(e) => onFileChange(e)}
              />
            </div>
          </div>
        ) : null}
        {pdfBytes && (
          <>
            <div className='row'>
              <div className={windowSize.width < 768 ? 'col-md-1 mt-3 d-flex justify-content-center':'col-md-1 mt-3'}>
                <div className= {windowSize.width < 768 ? 'btn-group':'btn-group-vertical'}>
                  <button className="btn btn-dark" title="Add Signature" onClick={handleShow}>
                    <FontAwesomeIcon icon={faSignature}/>
                  </button>
                  <button className="btn btn-dark" title="Zoom in"  onClick={handleZoomInClick}>
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </button>
                  <button className="btn btn-dark" title="Zoom Out"  onClick={handleZoomOutClick}>
                    <FontAwesomeIcon icon={faSearchMinus} />
                  </button>
                  <button className="btn btn-dark" title="Download" onClick={handleDownloadClick}>
                    <FontAwesomeIcon icon={faDownload} />
                  </button>                  
                  <button className="btn btn-dark" title="Delete"  onClick={handleDeleteAnnotatedText}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <button className="btn btn-dark" title='Close' onClick={handleClickClear}>
                    <FontAwesomeIcon icon={faTimes}/>
                  </button>
                </div>
              </div>
              <div className='col-md-11'>
                <div className='col-md-12 mt-3 d-flex justify-content-center'>
                  <div className={`pdf-wrapper border`} style={{ transform: `scale(${zoom})` }}>
                    <Document
                      file={pdfUrl}
                      onLoadSuccess={(data) => {
                        setTotalPages(data.numPages);
                      }}
                    >
                      <Page
                        key={`page_${pageNum}`}
                        pageNumber={pageNum + 1}
                        onRenderError={(error) => console.error(error)}
                        width={600}
                        height={800}
                        renderTextLayer={false}
                      >
                        {
                          signatureDragableVisiable && 
                          <DraggableSignature
                            initialTop={100}
                            initialLeft={100}
                            pdfBytes={pdfBytes}
                            setModifiedPdf={setModifiedPdf}
                            handleUpdateFile={handleUpdateFile}
                            pageNumber={pageNum}
                            legalName={legalName}
                            ipAddress={ipAddress}
                            signatureURL={signatureURL}
                            signatureTime={signatureTime}
                            signatureDate={signatureDate}
                            setPdfBytes={setPdfBytes}
                            setSignatureDragableVisiable={setSignatureDragableVisiable}
                            setLegalName={setLegalName}
                            setSignatureURL={setSignatureURL}
                          />
                        }
                      </Page>
                    </Document>
                  </div>
              </div>
              <div className='col-md-12 d-flex justify-content-center'>
                <PagingControl
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  totalPages={totalPages}
                />
              </div>
              </div>
            </div>
            <div id="myModal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className='py-2 bg-warning'>
                    Signature
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                          <label>Legal Name</label>
                          <input type="text" value={legalName} name="legalName"  onChange={(e) => setLegalName(e.target.value)} className='form-control'/>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                          <label>Date</label>
                          <input type="text" readOnly value={signatureDate} className='form-control'/>
                      </div>
                      <div className="col-md-4">
                          <label>Time</label>
                          <input type="text" readOnly value={signatureTime} className='form-control'/>
                      </div>
                      <div className="col-md-4">
                          <label>IP</label>
                          <input type="text" readOnly value={ipAddress} className='form-control'/>
                      </div>
                      <div className="col-md-12">
                          <label className="mt-2">Draw your signature Below</label>
                          <div>
                              <SignatureCanvas
                                velocityFilterWeight={1}
                                ref={sigRef}
                                canvasProps={{
                                width: "470",
                                height: 200,
                                className: "sigCanvas border",
                                }}
                              />
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button 
                          type='button'
                          onClick={() => {
                              const sigURL = sigRef.current.toDataURL();
                              setSignatureURL(sigURL);
                              handleClose();
                              setSignatureDragableVisiable(true);
                          }}
                      >
                          Save
                      </button>
                      <button  type='button'  onClick={handleClearSignatureData}>
                          Clear
                      </button>
                      <button  type='button' onClick={handleClose}>
                          Close
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Signature;