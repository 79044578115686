/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const CalcButton = (props) => {
  const { value, colored, span , equal } = props;
  const classList = `calc__btn
      ${colored ? ' btn--colored' : ''} 
      ${equal ? ' btn--equal' : ''}  
      ${span !== 1 ? ` btn--span-${span}` : ''}`;
  return (
    <button type="button" className={classList} data-name={value}>
      {value}
    </button>
  );
};

CalcButton.propTypes = {
  value: PropTypes.string,
  colored: PropTypes.bool,
  span: PropTypes.number,
  name: PropTypes.string,
  equal: PropTypes.bool,
};

CalcButton.defaultProps = {
  value: 'X',
  colored: false,
  span: 1,
};

export { CalcButton as default };
