/* eslint-disable */
import React, { useState} from 'react';
import {goToTop} from './CssHelper';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Clock(){

    /*
        lawyer-hourly-rate-calculator/
    */
    const [makePerYear, setMakePerYear] = useState(0);
    const [businessMonthlyExpense, setBusinessMonthlyExpense] = useState(0);
    const [hoursPerWeek, setHoursPerWeek] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [hourlyRate, setHourlyRate] = useState(0);
    const [showHourlyRate, setShowHourlyRate] = useState(false);
    const [copied, setCopied] = useState(false);

    function handleChangeMakePerYear(e : any){
        setMakePerYear(e.target.value);
    }

    function handleChangeBusinessMonthlyExpense(e : any){
        setBusinessMonthlyExpense(e.target.value);
    }

    function handleChangeHoursPerWeek(e : any){
        setHoursPerWeek(e.target.value);
    }

    function handleClickHourlyRate()
    {
        if (makePerYear && hoursPerWeek &&  businessMonthlyExpense){
            let hourlyRate = ( ( makePerYear / 0.7 ) + ( businessMonthlyExpense * 12 ) ) / ( ( hoursPerWeek / 5 / 3 ) * 241 )
            setHourlyRate(hourlyRate);
            setErrorMessage('');
            setShowResult(true);
            setShowHourlyRate(true);
            setShowTotalBill(false);
            goToTop();     
        }else{
            setErrorMessage('All Fields are required');
        }
    }

    function handleClickClearHourlyRate(){
        setMakePerYear(0);
        setBusinessMonthlyExpense(0);
        setHoursPerWeek(0);
        setHourlyRate(0);
        setShowResult(false);
        setErrorMessage('');
        setShowHourlyRate(false);
        handleClickClearBill();
    }

    /**
     * attorney-billable-hours-calculator/
    */

    const [billAbleHoursRate, setBillableHoursRate] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalBill, setTotalBill] = useState(0);
    const [showTotalBill, setShowTotalBill] = useState(false);

    function handleChangeBillableHoursRate(e : any){
        setBillableHoursRate(e.target.value);
    }

    function handleChangeHours(e : any){
        setHours(e.target.value);
    }

    function handleChangeMinutes(e : any){
        setMinutes(e.target.value);
    }

    function handleClickCalculateBillableHours(){
        if (billAbleHoursRate && hours && minutes ){
            let totalBill = (billAbleHoursRate * hours) + (billAbleHoursRate * (minutes/60));
            setTotalBill(totalBill);
            setShowResult(true);
            setShowHourlyRate(false);
            setShowTotalBill(true);
            setErrorMessage('');
            goToTop();
        }else{
            setErrorMessage('All Fields are required');
        }
    }


    function handleClickClearBill(){
        setBillableHoursRate(0);
        setHours(0);
        setMinutes(0);
        setErrorMessage('');
        setShowResult(false);
        setShowTotalBill(false);
        handleClickClearHourlyRate();
    }

    return(
        <div className='top-100'>
             <div className="row justify-content-center">
                {errorMessage &&
                    <div className='col-md-8'>
                        <div className="errorMessage">
                            <p className="mb-0">{errorMessage}</p>
                        </div>
                    </div>
                }
            </div>
            <div className="row justify-content-center"> 
                <div className="col-md-8 mt-2">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#hourly_rate" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                    Hourly Rate
                                    </h5>
                                </button>
                            </div>
                            <div id="hourly_rate" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {showResult && showHourlyRate && 
                                        <div className=' col-12 mb-3 px-0'>
                                        <div className="output d-flex">
                                            <h3 className='bg-warning px-4 mb-0 d-flex align-items-center'>Result</h3>
                                            <div>
                                                <p className="mb-1 pl-4 d-flex align-items-center justify-content-center">
                                                    Hourly Rate: ${hourlyRate.toLocaleString('en-US', {maximumFractionDigits:2})}
                                                    <CopyToClipboard text={'Hourly Rate: ' + hourlyRate.toLocaleString('en-US', {maximumFractionDigits:2}) + "."}
                                                        onCopy={() => setCopied(true)}>
                                                        <button className="btn btn-dark ml-4">Copy</button>
                                                    </CopyToClipboard>    
                                                </p>                                                                    
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="form-group" >
                                                <label>How much do you need to make per year? <span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input type="text" value={makePerYear} onChange={handleChangeMakePerYear} className="form-control col-md-12" name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Estimate your monthly business expenses*</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input  type="text" value={businessMonthlyExpense} onChange={handleChangeBusinessMonthlyExpense} className='form-control col-md-12' name="" id=""/>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>How many hours per week are you willing to work, on average?</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">more_time</i>
                                                    </span>
                                                    <input type="text" value={hoursPerWeek} onChange={handleChangeHoursPerWeek} className='form-control col-md-12' name="" id=""/>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickHourlyRate} className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearHourlyRate} className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#billable_hours" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Billable Hours
                                    </h5>
                                </button>
                            </div>
                            <div id="billable_hours" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {showResult && showTotalBill &&
                                        <div className=' col-12 mb-3 px-0'>
                                            <div className='output d-flex'>
                                                <h3 className='bg-warning px-4 mb-0 d-flex align-items-center'>Result</h3>
                                                <div>
                                                    <p className="mb-1 pl-4 d-flex align-items-center justify-content-center">
                                                        Total Bill for the Period: ${totalBill}
                                                        <CopyToClipboard text={'Total Bill for the Period: ' + totalBill.toLocaleString('en-US', {maximumFractionDigits:2}) + "."}
                                                            onCopy={() => setCopied(copied)}>
                                                            <button className="btn btn-dark ml-4">Copy</button>
                                                        </CopyToClipboard>
                                                    </p>
                                                    
                                                </div>
                                            </div>                                            
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Hourly Rate</label>
                                                <div className="input-group">
                                                <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">attach_money</i>
                                                    </span>
                                                    <input  type="text" value={billAbleHoursRate} onChange={handleChangeBillableHoursRate}  className='form-control col-md-12' name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group" >
                                                <label>Hours</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">watch_later</i>
                                                    </span>
                                                    <input type="text" value={hours} onChange={handleChangeHours}  className="form-control col-md-12" name="" id=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Minutes</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon bg-dark text-white pt-2  px-1">
                                                        <i className="material-icons">watch_later</i>
                                                    </span>
                                                    <input type="text"value={minutes} onChange={handleChangeMinutes}   className="form-control col-md-12" name="" id=""/>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={handleClickCalculateBillableHours}  className="btn btn-warning col-12">Calculate</button>
                                        </div>
                                        <div className="col-6">
                                        <button onClick={handleClickClearBill}  className="btn btn-dark col-12">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clock;