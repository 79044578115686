/* eslint-disable */
import * as React from "react";
import ReactDOM from 'react-dom';

function Apps(){
    return(
        <div className="top-100">
            <div className="row justify-content-center" id="adr" >
                <div className="col-md-8 mt-4" id="apps">
                    <div id="accordion" >
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Distribution Channels
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse5" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Use our 40+ distribution channel applications, apps, extensions, add-ons, 
                                                add-ins, and smartwatch faces for PCs, mobile devices, web browsers, and other 
                                                industry leading third party products.
                                            </p>
                                            <p>
                                                Our Web 3.0 content management platform is powered by our proprietary Toolkit.law
                                                Admin Server. It instantly updates all content across these sites and all our 
                                                distribution channels, bypassing the need for frequent app store updates. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Operating Systems
                                    </h5>
                                </button>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Install the Toolkit.law App for these PC and mobile device operating systems. 
                                                Note that the Chrome OS, Unix, and Linux operating systems are supported through 
                                                our browser extension. 
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Apple iOS                                            
                                            </a>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Apple Mac OS X
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Google Android OS                                          
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Google Chrome OS/Unix/Linux
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Windows 11                                            
                                            </a>
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                        </div> */}
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Browser Extensions
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse2" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Follow <a href="https://www.wikihow.com/Pin-Extensions-in-Chrome" target="blank" rel="noreferrer">these or similar instructions</a> on how to make your browser extension 
                                                to make it visible on your toolbar by turning on the “Pin Extension” setting. 
                                                Then drag our toolbar icon to the far left of the toolbar.  
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {/* <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Apple Safari browser                                         
                                            </a>
                                        </div> */}
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='https://chrome.google.com/webstore/detail/toolkitlaw-app/ekfjmholdieliedmjndkcocegkcaafdg' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Google Chrome browser
                                            </a>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='https://microsoftedge.microsoft.com/addons/detail/toolkitlaw-app/lghniknkldhlddjaekfecclhialclpoi' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Edge browser                                          
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://addons.mozilla.org/en-US/firefox/addon/toolkit-law-app-extension/' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Mozilla Firefox browser
                                            </a>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Microsoft 365/Office
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse3" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Follow these Microsoft 365 program add-ins <a href="https://support.microsoft.com/en-us/office/view-manage-and-install-add-ins-for-microsoft-365-programs-16278816-1948-4028-91e5-76dca5380f8d" rel='noreferrer' target="_blank">installation instructions.</a>
                                                The Toolkit.law App supports and auto installs on each version of each of the below 
                                                programs associated with the same Microsoft 365 account for Microsoft Windows, 
                                                Apple Mac OS X, Apple iOS, Google Android, and website versions. Thus, we support all 
                                                30 variations of these Microsoft 365/Office programs. 
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Excel                                        
                                            </a>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft OneNote
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Outlook                                         
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft PowerPoint
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Teams                                        
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Microsoft Word
                                            </a>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Adobe Acrobat
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse6" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Follow these Adobe Acrobat add-ons, plug-in and extensions <a href="https://helpx.adobe.com/creative-cloud/kb/installingextensionsandaddons.html" rel='noreferrer' target="_blank">installation instructions.</a>. The Toolkit.law App supports and auto installs on each version of 
                                                each of the below programs associated with the same Adobe Acrobat account for 
                                                Microsoft Windows, Apple Mac OS X, Apple iOS, Google Android, and website versions. 
                                                Thus, we support all 15 variations of these Microsoft 365/Office programs.  
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='http://adobe.com/acrobat' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Adobe Acrobat DC                                      
                                            </a>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='http://adobe.com/acrobat' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Adobe Acrobat Reader
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='http://adobe.com/acrobat' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Adobe Acrobat Standard                                          
                                            </a>
                                        </div>
                                        <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='http://adobe.com/acrobat' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Adobe Acrobat Professional
                                            </a>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Zoom Videoconferencing
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse7" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Follow these Zoom video conferencing app <a href="https://support.zoom.us/hc/en-us/articles/360061554732-Getting-started-with-Zoom-Apps-" rel='noreferrer' target="_blank">installation instructions.</a>. 
                                                to install our app for Zoom from the <a href="https://marketplace.zoom.us/" target="_blank" rel='noreferrer'>Zoom Apps Marketplace</a>. 
                                                You need to manually install the Toolkit.law App manually into each 
                                                of the below supported variations.  See also the <a href="https://www.zoom.us/download"  target="_blank" rel="noreferrer">Zoom Download Center </a> 
                                                 and <a href='https://support.zoom.us/hc/en-us' target="_blank" rel="noreferrer">Zoom Support</a>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Zoom for Apple Mac OS X                                        
                                            </a>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='' target="_blank" rel="noreferrer" className='btn btn-dark col-md-8 video-button'>
                                                Zoom for Microsoft Windows
                                            </a>
                                        </div>
                                    </div>                           
                                </div>
                            </div>
                        </div> */}
                        <div className="card">
                            <div className="card-header bg-warning" id="headingOne">
                                <button className="btn btn-warning w-100" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="mb-0 text-left">                                    
                                        Smartwatches
                                    </h5>
                                </button>
                            </div>
                            <div id="collapse8" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <p>
                                                Install the Toolkit.law App watch face for these watch/wearable operating
                                                systems. Then tap the logo for the QRC image to scan with your phone for 
                                                quick access to information i.e., such as when you are in front of a judge 
                                                wanting a mediator's name, date, and time of availability to add into the 
                                                “Order of Referral to Mediate”. 
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {/* <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='#' target="_blank" rel="noreferrer" className='btn btn-dark col-md-12 video-button'>
                                                Watch Face for Apple WatchOS                                      
                                            </a>
                                        </div> */}
                                        <div className='col-md-6 d-flex justify-content-center'>
                                            <a href='https://play.google.com/store/apps/details?id=com.watchfacestudio.toolkitlaw2' target="_blank" rel="noreferrer" className='btn btn-dark col-md-12 video-button'>
                                                Watch Face for Google Wear OS
                                            </a>
                                        </div>
                                        {/* <div className='col-md-6 mt-2 d-flex justify-content-center'>
                                            <a href='https://drive.google.com/file/d/1gmP1hZpUlW2VL3hZO1oN2cNGPIR6ni49/view?usp=sharing' target="_blank" rel="noreferrer" className='btn btn-dark col-md-12 video-button'>
                                                Watch Face for Samsung Tizen OS (manual install)                                          
                                            </a>
                                        </div> */}
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Apps;