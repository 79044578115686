/* eslint-disable */
import React, { useState } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {goToTop} from './CssHelper';

function DateCalculator()
{
    let today = moment().format('YYYY-MM-DD');
    // document.getElementById("start_date").valueAsDate = today;
    const [startDate, setStartDate] = useState(today);
    const [operation, setOperation] = useState('add');
    const [years, setYears] = useState('');
    const [days, setDays] = useState('');
    const [weeks, setWeeks] = useState('');
    const [months, setMonths] = useState('');
    const [results, setResult] = useState('');
    const [copied, setCopied] = useState(false);
    const [showNewDate, setShowNewDate] = useState(false);


    function handleChangeStartDate(e){
        setStartDate(e.target.value);
    }

    function handleChangeOperation(e){
        setOperation(e.target.value);
    }

    function handleChangeYears(e){
        setYears(e.target.value);
    }

    function handleChangeDays(e){
        setDays(e.target.value);
    }

    function handleChangeWeeks(e){
        setWeeks(e.target.value);
    }

    function handleChangeMonths(e){
        setMonths(e.target.value);
    }


    function handleCalculate() {
        // Logic here
        setCopied(false);
        let date = moment(startDate).format(); // Format the startDate as string
      
        if (operation === 'add') 
        {
          if (days) {
            date = moment(date).add(days, 'days').format(); // Convert Moment to string
          }

          if (months) {
            date = moment(date).add(months, 'months').format(); // Convert Moment to string
          }

          if (years) {
            date = moment(date).add(years, 'years').format(); // Convert Moment to string
          }

          if (weeks) {
            date = moment(date).add(weeks, 'weeks').format(); // Convert Moment to string
          }

        }else if (operation === 'subtract') {
          if (days) {
            date = moment(date).subtract(days, 'days').format(); // Convert Moment to string
          }
          if (months) {
            date = moment(date).subtract(months, 'months').format(); // Convert Moment to string
          }
          if (years) {
            date = moment(date).subtract(years, 'years').format(); // Convert Moment to string
          }
          if (weeks) {
            date = moment(date).subtract(weeks, 'weeks').format(); // Convert Moment to string
          }
        }
      
        if (days || weeks || years) {
          setResult(date);
        } else {
          setResult('');
        }
        setShowNewDate(true);
        goToTop();
    }      

    function handleClickClear(){
        setShowNewDate(false);
        setMonths('');
        setWeeks('');
        setYears('');
        setDays('');
        setStartDate(today);
        setOperation('add');
    }
    
    return(
        <div className="top-100">
            <div id="datecalculator" className="w-100 ">
                <div className="container">
                    <div className="row justify-content-center">
                        { showNewDate &&
                            <div className="col-md-8">
                                <div className="output pb-1">
                                    <h2 className="bg-warning pl-3"> Result </h2>
                                    <p className="mb-1 pl-4">{showNewDate && <Moment date={results} format="LLLL" />}</p>
                                    <p className="mb-1">
                                        {showNewDate && 
                                            <div className='col-6 col-md-6'>
                                                <CopyToClipboard text={results != '' ? moment(results).format("LLLL") + "." : results + "."}
                                                    onCopy={() => setCopied(true)}>
                                                    <button className="btn btn-dark btn-block">Copy</button>
                                                </CopyToClipboard>
                                            </div>
                                        }
                                    </p>
                                    <p className="mb-1 pl-4">
                                        {copied && showNewDate  ? <span style={{color: 'red'}}>Copied.</span> : null}
                                    </p>
                                </div>
                            </div>
                        }
                        <div className="col-12 col-md-8 pl-4 mt-2">
                            <div className="form-group">
                                <label>Start Date</label><br/>
                                <input required value={startDate} onChange={handleChangeStartDate} className="form-control col-md-12" type="date" name="start_date" id="start_date" />
                            </div>
                            <div className="form-group">
                                <label>Add/Subtract</label><br/>
                                <select value={operation} onChange={handleChangeOperation} className="form-control col-md-12" id="operation" name="operation">
                                    <option value="add">Add</option>
                                    <option value="subtract">Subtract</option>
                                </select>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-3 d-inline">
                                    <div className="form-group">
                                        <label>Year</label>
                                        <input value={years} onChange={handleChangeYears} className="form-control" type="number" name="year" id="year" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 d-inline">
                                    <div className="form-group">
                                        <label>Month</label>
                                        <input value={months} onChange={handleChangeMonths} className="form-control" type="number" name="month" id="month" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 d-inline">
                                    <div className="form-group">
                                        <label>Week</label>
                                        <input value={weeks} onChange={handleChangeWeeks} className="form-control" type="number" name="week" id="week" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 d-inline" >
                                    <div className="form-group">
                                        <label>Day</label>
                                        <input value={days} onChange={handleChangeDays} className="form-control" type="number" name="day" id="day" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <button onClick={handleCalculate} className="btn btn-warning text-white btn-block">
                                        Calculate
                                    </button>
                                </div>
                                <div className='col-6 col-md-6'>
                                <button onClick={handleClickClear} className='btn btn-dark btn-block'>Clear</button>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default DateCalculator;