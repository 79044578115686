/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import { useDrag } from 'react-use-gesture';
import { PDFDocument } from 'pdf-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faHand } from '@fortawesome/free-solid-svg-icons';
import { pdfjs } from 'react-pdf';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import dayjs from 'dayjs';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DraggableTextField(props) {
    const
        {
            top: initialTop,
            left: initialLeft,
            pdfBytes,
            setModifiedPdf,
            handleUpdateFile,
            pageNumber,
            setPdfBytes,
            setDragableFieldVisible
        } = props;

    const [text, setText] = useState('');
    const [top, setTop] = useState(initialTop);
    const [left, setLeft] = useState(initialLeft);
    const [isDateTime, setIsDateTime] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isIpAddress, setIsIpAddress] = useState(false);
    const [ipAddress, setIpAddress] = useState(); // IP address
    const [dateTime, setDateTime] = useState(dayjs().format("DD MMM YYYY HH:mm:ss")); // DateTime
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(dayjs().format("DD MMM YYYY HH:mm:ss"));
        }, 1000); // Update the time every second

        return () => {
            clearInterval(interval); // Clean up the interval on component unmount
        };
    }, []);

    const handleClickIsItalic = () => {
        setIsItalic(!isItalic);
    };

    const handleClickIsDateTime = () => {
        setIsDateTime(!isDateTime);
    };

    const handleClickIsIpAddress = () => {
        setIsIpAddress(!isIpAddress);
    };

    useEffect(() => {
        getIp();
    }, [ipAddress]);

    async function getIp() {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
    }

    const bind = useDrag(({ down, movement: [mx, my] }) => {
        if (down && mx && my) {
            ref.current.style.cursor = 'grabbing';

            // Calculate the boundaries of the PDF file
            const pdfWidth = 600; // Width of a letter-sized PDF in points
            const pdfHeight = 800; // Height of a letter-sized PDF in points
            const textFieldWidth = 200; // Width of the text field in pixels
            const textFieldHeight = 50; // Height of the text field in pixels
            const maxWidth = pdfWidth - textFieldWidth;
            const maxHeight = pdfHeight - textFieldHeight;

            // Calculate the new position of the text field
            const newTop = Math.max(0, Math.min(maxHeight, initialTop + my));
            const newLeft = Math.max(0, Math.min(maxWidth, initialLeft + mx));

            // Update the state only if the new position is within the boundaries
            setTop(newTop);
            setLeft(newLeft);
        } else {
            ref.current.style.cursor = 'grab';
        }
    });

    const handleInputChange = (event) => {
        setText(event.target.value.toString());
    };

    const handleSaveClick = async () => {

        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Set the font to italic
        const italicFont = await pdfDoc.embedFont('Helvetica-Oblique');
        const normalFont = await pdfDoc.embedFont('Helvetica');

        // Loop through all the text fields and add them to the first page
        const pages = pdfDoc.getPages();

        // const { top: currentTop, left: currentLeft } = textField;
        const page = pages[pageNumber];
        const textData = text || '';

        // Add a new text field to the page
        const textFieldContent = {
            text: textData,
            size: 12,
            font: isItalic ? italicFont : normalFont
        };

        // text
        const y = page.getHeight() - top - 10;
        page.drawText(textData, {
            x: left + textFieldContent.size,
            y: y - textFieldContent.size,
            ...textFieldContent
        });

        // datetime
        const dateTimeAndIpAddress = {
            text: textData,
            size: 9,
            font: normalFont
        };

        if (isDateTime) {
            page.drawText(dateTime, {
                x: left + textFieldContent.size,
                y: y - textFieldContent.size * 2,
                ...dateTimeAndIpAddress
            });
        }

        // ipaddress
        if (isIpAddress) {
            page.drawText(ipAddress, {
                x: left + textFieldContent.size,
                y: y - textFieldContent.size * 3,
                ...dateTimeAndIpAddress
            });
        }

        // Update the modified PDF document and set the state
        const modifiedPdfBytes = await pdfDoc.save();
        setModifiedPdf(new Uint8Array(modifiedPdfBytes));
        setPdfBytes(new Uint8Array(modifiedPdfBytes));
        handleUpdateFile(new Uint8Array(modifiedPdfBytes));
        setDragableFieldVisible(false);
    };

    const handleClickClear = () => {
        setText('');
        setDragableFieldVisible(false);
    };
    return (
        <div
            className="draggable-text-field border bg-white px-4 pt-4 pb-2"
            ref={ref}
            {...bind()}
            style={{ position: 'absolute', top: `${top}px`, left: `${left}px`, cursor: 'grab' }}
        >
            <div className="d-flex justify-content-center">
                <button className='btn btn-warning'>
                    <FontAwesomeIcon icon={faHand}/>
                </button>
            </div>
            <div className="input-group">
                <input type="text" value={text} onChange={handleInputChange}/>
                <div className='input-group-prepend'>
                    <button className='btn btn-success btn-sm' onClick={handleSaveClick}>
                        <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button className='btn btn-danger btn-sm' onClick={(e) => handleClickClear()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
            <div className='items'>
                <label className='d-flex mt-2'>
                    <Toggle
                        aria-label='Text in Italics'
                        checked={isItalic}
                        onClick={handleClickIsItalic}
                    />
                    <span className='ml-4 label-text'>Italics</span>
                </label>
                <label className='d-flex'>
                    <Toggle
                        aria-label='No label tag'
                        checked={isIpAddress}
                        onClick={handleClickIsIpAddress}
                    />
                    <span className='ml-4 label-text'>IP Address</span>
                </label>
                <label className='d-flex'>
                    <Toggle
                        aria-label='No label tag'
                        checked={isDateTime}
                        onClick={handleClickIsDateTime}
                    />
                    <span className='ml-4 label-text'>International Time</span>
                </label>
            </div>

        </div>
    );
}

export default DraggableTextField;
